import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function fetchAccountInformation() {
  const { jwt } = await getAuthInfo();
  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/accountinfo`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: jwt,
      }),
    }
  ).catch((err) =>
    throwEndpointError(err, res, 'Error fetching account information', false)
  );

  if (!res.ok) {
    throwEndpointError({}, res, 'Error fetching account information', false);
    return;
  }

  const data = await res.json();
  console.log('Account Information', data);
  // eslint-disable-next-line consistent-return
  return data;
}
