import { getCodeBillingPeriod, isCodeFreemium } from '../../../utils';

export default function onAppSetUserSubscriptionData(state, payload) {
  const { subscription } = payload;
  const user = { ...state.user };
  user.subscription = subscription;
  user.subscription.isFree = isCodeFreemium(subscription.plan.code);
  user.subscription.billingPeriod = getCodeBillingPeriod(
    subscription.plan.code
  );
  return { ...state, user };
}
