import { getAuthInfo } from '.';
import { throwEndpointError } from '../../../utils';

export default async function fetchSubscriptionInformation() {
  const { jwt } = await getAuthInfo();

  const res = await fetch(
    `${process.env.GATSBY_AMWELL_API_ENDPOINT}/subscription/subscriptions`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: jwt,
      }),
    }
  ).catch((err) =>
    throwEndpointError(
      err,
      res,
      'Error retrieving subscription information',
      false
    )
  );

  if (!res.ok) {
    throwEndpointError(
      {},
      res,
      'Error retrieving subscription information',
      false
    );
    return;
  }

  const data = await res.json();
  console.log('Subscription Information', data);
  // eslint-disable-next-line consistent-return
  return data;
}
