import React from 'react'
import { AppProvider } from './src/lib/providers'
import './src/styles/styles.scss'
import Amplify from 'aws-amplify'
import { Helmet } from 'react-helmet'

const awsConfig = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.GATSBY_COGNITO_REGION,
    userPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_COGNITO_APP_CLIENT_ID
  },
  Analytics: {
    disabled: true,
  },
}

Amplify.configure(awsConfig)

export const wrapRootElement = ({ element }) => {
  return (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Amwell Private Practice</title>
    </Helmet>
    <AppProvider>{element}</AppProvider>
  </>
)}
