import React from 'react';

export default function AmwellBlueHeart() {
  return (
    <svg
      width="38"
      height="35"
      viewBox="0 0 38 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5934 35C18.161 35 17.7286 34.8306 17.409 34.5106L12.6525 29.805C11.9945 29.1462 11.9757 28.0733 12.6337 27.3957C13.2917 26.7369 14.3633 26.7181 15.0401 27.3769L18.6122 30.9155L31.3212 18.4738C34.5549 15.0104 34.5361 9.40128 31.3024 5.95675C29.9488 4.52623 28.2568 3.66039 26.3767 3.45334C23.9515 3.18983 21.5451 4.09331 19.7966 5.9191C19.477 6.25791 19.0258 6.44613 18.5746 6.44613C18.1046 6.44613 17.6722 6.25791 17.3526 5.9191C15.9426 4.43212 14.0625 3.52863 12.0885 3.4157C9.73845 3.28394 7.48241 4.20625 5.82798 5.95675C3.62834 8.29075 2.83872 11.7165 3.77874 14.8975C4.04195 15.801 3.53434 16.7421 2.63192 17.0056C1.7295 17.2691 0.789488 16.7609 0.526283 15.8574C-0.75214 11.5282 0.33828 6.84141 3.36513 3.62275C5.69637 1.13816 8.94883 -0.179417 12.2577 0.0276312C14.5513 0.159389 16.7322 1.02523 18.5558 2.45575C20.887 0.611132 23.7823 -0.254708 26.7339 0.0652764C29.3848 0.347615 31.8664 1.60873 33.7465 3.60392C38.2022 8.34722 38.2022 16.0645 33.7465 20.8078C33.7277 20.8266 33.7089 20.8454 33.6901 20.8642L19.7778 34.4918C19.4394 34.8306 19.007 35 18.5934 35Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M9.87628 26.25C9.38367 26.25 8.91001 26.0596 8.53108 25.6978L4.02184 21.2802C3.26399 20.5375 3.24504 19.3189 3.98395 18.5382C4.72286 17.7765 5.93543 17.7575 6.71223 18.5001L9.87628 21.6039L20.8083 10.8263C21.5662 10.0837 22.7788 10.0837 23.5366 10.8454C24.2755 11.607 24.2755 12.8257 23.5177 13.5874L11.2404 25.6788C10.8425 26.0596 10.3499 26.25 9.87628 26.25Z"
        fill="url(#paint1_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="18.5526"
          y1="33.5135"
          x2="18.5526"
          y2="2.15342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0469BD" />
          <stop offset="1" stopColor="#25ABFD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="3"
          y1="18"
          x2="24"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF9C23" />
          <stop offset="1" stopColor="#FCD54E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
