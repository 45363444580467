// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-acccount-index-js": () => import("./../../../src/pages/acccount/index.js" /* webpackChunkName: "component---src-pages-acccount-index-js" */),
  "component---src-pages-checkout-confirmation-js": () => import("./../../../src/pages/checkout/confirmation.js" /* webpackChunkName: "component---src-pages-checkout-confirmation-js" */),
  "component---src-pages-checkout-index-js": () => import("./../../../src/pages/checkout/index.js" /* webpackChunkName: "component---src-pages-checkout-index-js" */),
  "component---src-pages-dashboard-account-index-js": () => import("./../../../src/pages/dashboard/account/index.js" /* webpackChunkName: "component---src-pages-dashboard-account-index-js" */),
  "component---src-pages-dashboard-billing-index-js": () => import("./../../../src/pages/dashboard/billing/index.js" /* webpackChunkName: "component---src-pages-dashboard-billing-index-js" */),
  "component---src-pages-dashboard-billing-information-js": () => import("./../../../src/pages/dashboard/billing/information.js" /* webpackChunkName: "component---src-pages-dashboard-billing-information-js" */),
  "component---src-pages-dashboard-documents-index-js": () => import("./../../../src/pages/dashboard/documents/index.js" /* webpackChunkName: "component---src-pages-dashboard-documents-index-js" */),
  "component---src-pages-dashboard-help-js": () => import("./../../../src/pages/dashboard/help.js" /* webpackChunkName: "component---src-pages-dashboard-help-js" */),
  "component---src-pages-dashboard-index-js": () => import("./../../../src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-dashboard-plan-cancel-js": () => import("./../../../src/pages/dashboard/plan/cancel.js" /* webpackChunkName: "component---src-pages-dashboard-plan-cancel-js" */),
  "component---src-pages-dashboard-plan-change-js": () => import("./../../../src/pages/dashboard/plan/change.js" /* webpackChunkName: "component---src-pages-dashboard-plan-change-js" */),
  "component---src-pages-dashboard-plan-index-js": () => import("./../../../src/pages/dashboard/plan/index.js" /* webpackChunkName: "component---src-pages-dashboard-plan-index-js" */),
  "component---src-pages-dashboard-plan-user-amount-js": () => import("./../../../src/pages/dashboard/plan/userAmount.js" /* webpackChunkName: "component---src-pages-dashboard-plan-user-amount-js" */),
  "component---src-pages-dashboard-users-index-js": () => import("./../../../src/pages/dashboard/users/index.js" /* webpackChunkName: "component---src-pages-dashboard-users-index-js" */),
  "component---src-pages-forgot-password-index-js": () => import("./../../../src/pages/forgotPassword/index.js" /* webpackChunkName: "component---src-pages-forgot-password-index-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-restricted-api-js": () => import("./../../../src/pages/restrictedAPI.js" /* webpackChunkName: "component---src-pages-restricted-api-js" */),
  "component---src-pages-signin-index-js": () => import("./../../../src/pages/signin/index.js" /* webpackChunkName: "component---src-pages-signin-index-js" */),
  "component---src-pages-signout-index-js": () => import("./../../../src/pages/signout/index.js" /* webpackChunkName: "component---src-pages-signout-index-js" */)
}

