import {
  CHECKOUT_BILLING_SECTION_KEY,
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_SIGNATURE_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
  CHECKOUT_SUMMARY_SECTION_KEY,
} from '..';

export default function onCheckoutInitCheckout(state, payload) {
  // eslint-disable-next-line no-param-reassign
  state.checkout = {
    pageReady: false,
    error: null,
    completed: false,
    availableSubscriptions: null,
    availableItems: null,
    currentSection: '',
    subtotal: { price: 0, money: '' },
    discount: { price: 0, money: '' },
    planSubtotal: { price: 0, money: '' },
    couponDiscount: { price: 0, money: '' },
    total: { price: 0, money: '' },
    coupon: null,
    acceptsToS: false,

    [CHECKOUT_PLAN_SECTION_KEY]: {
      key: CHECKOUT_PLAN_SECTION_KEY,
      status: 'incomplete',
      subscription: null,
      providerAccounts: 1,
      freePlan: null,
      billingPeriod: 'monthly',
      validation: {
        isValid: false,
        error: '',
        fields: {},
      },
    },

    [CHECKOUT_CONTACT_SECTION_KEY]: {
      key: CHECKOUT_CONTACT_SECTION_KEY,
      status: 'incomplete',
      contactInfo: {
        company: '',
        website: '',
        practiceSize: '',
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        password: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
      },
      validation: {
        isValid: false,
        error: '',
        fields: {},
      },
    },

    [CHECKOUT_SIGNATURE_SECTION_KEY]: {
      key: CHECKOUT_SIGNATURE_SECTION_KEY,
      status: 'incomplete',
      signature: '',
      validation: {
        isValid: false,
        error: '',
        fields: {},
      },
    },

    [CHECKOUT_BILLING_SECTION_KEY]: {
      key: CHECKOUT_BILLING_SECTION_KEY,
      status: 'incomplete',
      useContactInfo: true,
      ccLastFour: '',
      ccBrand: '',
      ccValid: false,
      billingInfo: {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: 'US',
      },
      validation: {
        isValid: false,
        error: '',
        fields: {},
      },
    },

    [CHECKOUT_PRACTICE_USER_SECTION_KEY]: {
      key: CHECKOUT_PRACTICE_USER_SECTION_KEY,
      status: 'incomplete',
      users: [],
      validation: {
        isValid: false,
        error: '',
        fields: {},
      },
    },

    [CHECKOUT_SUMMARY_SECTION_KEY]: {
      key: CHECKOUT_SUMMARY_SECTION_KEY,
    },
  };
  return { ...state };
}
