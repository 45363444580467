import React from 'react';

export default function AmwellLogo2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="130"
      height="35"
      viewBox="0 0 130 35"
      fill="none"
    >
      <path
        d="M45.0912 27.1631C46.0532 27.1631 46.7888 27.3343 47.3169 27.6957C47.8262 28.0571 48.0902 28.6087 48.0902 29.3506C48.0902 29.75 48.0148 30.0734 47.8828 30.3587C47.7507 30.6441 47.5432 30.8723 47.2792 31.0435C47.0151 31.2147 46.6945 31.3479 46.3172 31.443C45.94 31.5191 45.5062 31.5761 45.0158 31.5761H44.4122V33.9348H43.2051V27.3343C43.488 27.2772 43.7898 27.2202 44.1482 27.2011C44.4877 27.1631 44.8083 27.1631 45.0912 27.1631ZM45.1855 28.2093C44.8838 28.2093 44.6197 28.2283 44.4311 28.2473V30.5299H45.0158C45.6005 30.5299 46.0532 30.4538 46.355 30.2827C46.6756 30.1115 46.8265 29.8071 46.8265 29.3506C46.8265 29.1223 46.7888 28.9511 46.6945 28.799C46.619 28.6468 46.487 28.5327 46.355 28.4566C46.2041 28.3805 46.0343 28.3044 45.8268 28.2854C45.6194 28.2283 45.4119 28.2093 45.1855 28.2093Z"
        fill="#828282"
      />
      <path
        d="M51.0138 27.1638C51.9757 27.1638 52.7113 27.335 53.2206 27.6964C53.7299 28.0578 53.9751 28.5905 53.9751 29.3133C53.9751 30.2263 53.5224 30.835 52.6359 31.1584C52.7491 31.3106 52.8999 31.4818 53.0508 31.691C53.2017 31.9002 53.3526 32.1285 53.5224 32.3758C53.6733 32.6231 53.843 32.8703 53.9751 33.1367C54.1259 33.403 54.258 33.6502 54.3712 33.8975H53.0131C52.8999 33.6693 52.7679 33.441 52.617 33.1937C52.485 32.9655 52.3341 32.7372 52.1832 32.5089C52.0323 32.2807 51.9003 32.0714 51.7494 31.8812C51.6174 31.691 51.4853 31.5008 51.3533 31.3486C51.259 31.3486 51.1835 31.3676 51.127 31.3676C51.0704 31.3676 50.9949 31.3676 50.9383 31.3676H50.3536V33.8975H49.1465V27.297C49.4483 27.2399 49.7501 27.1828 50.0896 27.1638C50.4668 27.1638 50.7497 27.1638 51.0138 27.1638ZM51.1081 28.21C50.844 28.21 50.6177 28.229 50.3914 28.2481V30.4165H50.9195C51.2213 30.4165 51.4665 30.3975 51.6928 30.3595C51.9191 30.3214 52.1078 30.2644 52.2587 30.1883C52.4095 30.1122 52.5227 29.9981 52.5982 29.8459C52.6736 29.7127 52.7113 29.5225 52.7113 29.3133C52.7113 29.104 52.6736 28.9328 52.5982 28.7997C52.5227 28.6665 52.4095 28.5524 52.2587 28.4573C52.1078 28.3812 51.938 28.3051 51.7494 28.2861C51.5608 28.229 51.3344 28.21 51.1081 28.21Z"
        fill="#828282"
      />
      <path
        d="M55.334 27.2202H56.5411V33.9159H55.334V27.2202Z"
        fill="#828282"
      />
      <path
        d="M60.0293 33.9348C59.5578 32.8886 59.0862 31.8044 58.6335 30.663C58.1809 29.5217 57.7659 28.3804 57.3887 27.2391H58.7278C58.8787 27.6957 59.0296 28.1522 59.1994 28.6277C59.3503 29.1033 59.52 29.5598 59.6709 30.0163C59.8218 30.4728 59.9916 30.9103 60.1425 31.3288C60.2934 31.7473 60.4631 32.1467 60.614 32.5082C60.7649 32.1467 60.9158 31.7663 61.0667 31.3478C61.2176 30.9294 61.3873 30.4919 61.5571 30.0353C61.7268 29.5788 61.8777 29.1223 62.0475 28.6467C62.1984 28.1712 62.3681 27.7147 62.5002 27.2582H63.8016C63.4055 28.3995 62.9906 29.5408 62.5567 30.6821C62.1229 31.8234 61.6514 32.9076 61.1799 33.9538H60.0293V33.9348Z"
        fill="#828282"
      />
      <path
        d="M68.573 33.9348C68.4787 33.6685 68.3844 33.4022 68.2901 33.1549C68.1958 32.8886 68.1015 32.6413 68.0071 32.356H65.1968C65.1025 32.6223 65.0081 32.8886 64.9138 33.1549C64.8195 33.4212 64.7252 33.6685 64.6309 33.9348H63.3672C63.6124 33.212 63.8576 32.5652 64.0839 31.9565C64.3103 31.3478 64.5177 30.7772 64.7441 30.2446C64.9516 29.712 65.1779 29.1794 65.3854 28.7038C65.5929 28.2093 65.8192 27.7337 66.0455 27.2582H67.1961C67.4224 27.7337 67.6299 28.2283 67.8563 28.7038C68.0637 29.1984 68.2712 29.712 68.4975 30.2446C68.705 30.7772 68.9314 31.3669 69.1577 31.9565C69.384 32.5652 69.6292 33.231 69.8744 33.9348H68.573ZM66.6114 28.5326C66.4605 28.875 66.2907 29.2935 66.1021 29.7881C65.9135 30.2826 65.7249 30.8152 65.5174 31.3859H67.7054C67.4979 30.8152 67.3093 30.2826 67.1018 29.7881C66.9132 29.2745 66.7434 28.856 66.6114 28.5326Z"
        fill="#828282"
      />
      <path
        d="M74.7982 27.2202V28.2664H72.7611V33.9159H71.5351V28.2664H69.498V27.2202H74.7982Z"
        fill="#828282"
      />
      <path
        d="M75.7031 33.9348V27.2391H79.9847V28.2663H76.9291V29.9212H79.6452V30.9294H76.9291V32.8886H80.2111V33.9158H75.7031V33.9348Z"
        fill="#828282"
      />
      <path
        d="M85.4357 27.1638C86.3976 27.1638 87.1332 27.335 87.6613 27.6964C88.1706 28.0578 88.4347 28.6095 88.4347 29.3513C88.4347 29.7508 88.3592 30.0741 88.2272 30.3595C88.0952 30.6448 87.8877 30.8731 87.6236 31.0443C87.3595 31.2154 87.0389 31.3486 86.6617 31.4437C86.2844 31.5198 85.8506 31.5769 85.3602 31.5769H84.7566V33.9356H83.5684V27.335C83.8513 27.2779 84.1531 27.2209 84.5114 27.2019C84.851 27.1638 85.1527 27.1638 85.4357 27.1638ZM85.53 28.21C85.2282 28.21 84.9641 28.229 84.7755 28.2481V30.5307H85.3602C85.9449 30.5307 86.3976 30.4546 86.6994 30.2834C87.02 30.1122 87.1709 29.8078 87.1709 29.3513C87.1709 29.1231 87.1332 28.9519 87.0389 28.7997C86.9635 28.6475 86.8314 28.5334 86.6994 28.4573C86.5485 28.3812 86.3787 28.3051 86.1713 28.2861C85.9827 28.229 85.7563 28.21 85.53 28.21Z"
        fill="#828282"
      />
      <path
        d="M91.3771 27.1638C92.339 27.1638 93.0746 27.335 93.5839 27.6964C94.0931 28.0578 94.3383 28.5905 94.3383 29.3133C94.3383 30.2263 93.8857 30.835 92.9992 31.1584C93.1123 31.3106 93.2632 31.4818 93.4141 31.691C93.565 31.9002 93.7159 32.1285 93.8857 32.3758C94.0366 32.6231 94.2063 32.8703 94.3383 33.1367C94.4892 33.403 94.6213 33.6502 94.7344 33.8975H93.3764C93.2632 33.6693 93.1312 33.441 92.9803 33.1937C92.8483 32.9655 92.6974 32.7372 92.5465 32.5089C92.3956 32.2807 92.2636 32.0714 92.1127 31.8812C91.9806 31.691 91.8486 31.5008 91.7166 31.3486C91.6223 31.3486 91.5468 31.3676 91.4902 31.3676C91.4336 31.3676 91.3582 31.3676 91.3016 31.3676H90.7169V33.8975H89.5098V27.297C89.8116 27.2399 90.1133 27.1828 90.4528 27.1638C90.8112 27.1638 91.113 27.1638 91.3771 27.1638ZM91.4525 28.21C91.1884 28.21 90.9621 28.229 90.7358 28.2481V30.4165H91.2639C91.5657 30.4165 91.8109 30.3975 92.0372 30.3595C92.2636 30.3214 92.4522 30.2644 92.6031 30.1883C92.754 30.1122 92.8671 29.9981 92.9426 29.8459C93.018 29.7127 93.0557 29.5225 93.0557 29.3133C93.0557 29.104 93.018 28.9328 92.9426 28.7997C92.8671 28.6665 92.754 28.5524 92.6031 28.4573C92.4522 28.3812 92.2824 28.3051 92.0938 28.2861C91.9052 28.229 91.6977 28.21 91.4525 28.21Z"
        fill="#828282"
      />
      <path
        d="M100.337 33.9348C100.242 33.6685 100.148 33.4022 100.054 33.1549C99.9594 32.8886 99.8651 32.6413 99.7708 32.356H96.9604C96.8661 32.6223 96.7718 32.8886 96.6775 33.1549C96.5832 33.4212 96.4889 33.6685 96.3946 33.9348H95.1309C95.3761 33.212 95.6213 32.5652 95.8476 31.9565C96.0739 31.3478 96.2814 30.7772 96.5078 30.2446C96.7152 29.712 96.9416 29.1794 97.1491 28.7038C97.3565 28.2093 97.5829 27.7337 97.8092 27.2582H98.9598C99.1861 27.7337 99.3936 28.2283 99.6199 28.7038C99.8274 29.1984 100.035 29.712 100.261 30.2446C100.469 30.7772 100.695 31.3669 100.921 31.9565C101.148 32.5652 101.393 33.231 101.638 33.9348H100.337ZM98.3751 28.5326C98.2242 28.875 98.0544 29.2935 97.8658 29.7881C97.6772 30.2826 97.4886 30.8152 97.2811 31.3859H99.469C99.2616 30.8152 99.0729 30.2826 98.8655 29.7881C98.6768 29.2745 98.5071 28.856 98.3751 28.5326Z"
        fill="#828282"
      />
      <path
        d="M105.259 34.068C104.769 34.068 104.316 33.9919 103.92 33.8397C103.524 33.6876 103.184 33.4593 102.92 33.1549C102.637 32.8506 102.43 32.4892 102.279 32.0517C102.128 31.6142 102.053 31.1196 102.053 30.568C102.053 30.0164 102.128 29.5028 102.298 29.0843C102.468 28.6468 102.694 28.2854 102.996 27.981C103.298 27.6767 103.637 27.4484 104.033 27.2963C104.429 27.1441 104.863 27.068 105.316 27.068C105.599 27.068 105.844 27.087 106.07 27.1251C106.297 27.1631 106.485 27.2202 106.655 27.2582C106.825 27.3153 106.957 27.3723 107.07 27.4294C107.183 27.4865 107.259 27.5245 107.296 27.5626L106.938 28.5517C106.787 28.4566 106.561 28.3615 106.278 28.2854C105.995 28.1903 105.693 28.1522 105.354 28.1522C105.071 28.1522 104.807 28.2093 104.542 28.3044C104.297 28.3995 104.071 28.5517 103.901 28.7609C103.713 28.9702 103.581 29.2174 103.467 29.5218C103.373 29.8261 103.316 30.1685 103.316 30.568C103.316 30.9294 103.354 31.2528 103.43 31.5381C103.505 31.8424 103.637 32.0897 103.807 32.299C103.977 32.5082 104.184 32.6794 104.448 32.7935C104.712 32.9077 105.014 32.9647 105.372 32.9647C105.806 32.9647 106.146 32.9267 106.41 32.8316C106.674 32.7365 106.881 32.6604 107.032 32.5843L107.353 33.5734C107.277 33.6305 107.164 33.6685 107.032 33.7256C106.9 33.7827 106.749 33.8207 106.561 33.8778C106.391 33.9348 106.183 33.9729 105.957 33.9919C105.768 34.068 105.523 34.068 105.259 34.068Z"
        fill="#828282"
      />
      <path
        d="M113.201 27.2202V28.2664H111.163V33.9159H109.937V28.2664H107.9V27.2202H113.201Z"
        fill="#828282"
      />
      <path
        d="M114.125 27.2202H115.332V33.9159H114.125V27.2202Z"
        fill="#828282"
      />
      <path
        d="M119.878 34.068C119.388 34.068 118.935 33.9919 118.539 33.8397C118.143 33.6876 117.804 33.4593 117.54 33.1549C117.257 32.8506 117.049 32.4892 116.898 32.0517C116.747 31.6142 116.672 31.1196 116.672 30.568C116.672 30.0164 116.747 29.5028 116.917 29.0843C117.087 28.6468 117.313 28.2854 117.615 27.981C117.917 27.6767 118.256 27.4484 118.652 27.2963C119.048 27.1441 119.482 27.068 119.935 27.068C120.218 27.068 120.463 27.087 120.689 27.1251C120.916 27.1631 121.104 27.2202 121.274 27.2582C121.444 27.3153 121.576 27.3723 121.689 27.4294C121.802 27.4865 121.878 27.5245 121.915 27.5626L121.557 28.5517C121.406 28.4566 121.18 28.3615 120.897 28.2854C120.614 28.1903 120.312 28.1522 119.973 28.1522C119.69 28.1522 119.426 28.2093 119.162 28.3044C118.916 28.3995 118.69 28.5517 118.52 28.7609C118.332 28.9702 118.2 29.2174 118.086 29.5218C117.992 29.8261 117.936 30.1685 117.936 30.568C117.936 30.9294 117.973 31.2528 118.049 31.5381C118.124 31.8424 118.256 32.0897 118.426 32.299C118.596 32.5082 118.803 32.6794 119.067 32.7935C119.331 32.9077 119.633 32.9647 119.992 32.9647C120.425 32.9647 120.765 32.9267 121.029 32.8316C121.293 32.7365 121.5 32.6604 121.651 32.5843L121.972 33.5734C121.897 33.6305 121.783 33.6685 121.651 33.7256C121.519 33.7827 121.368 33.8207 121.18 33.8778C121.01 33.9348 120.803 33.9729 120.576 33.9919C120.369 34.068 120.142 34.068 119.878 34.068Z"
        fill="#828282"
      />
      <path
        d="M123.047 33.9348V27.2391H127.31V28.2663H124.254V29.9212H126.97V30.9294H124.254V32.8886H127.536V33.9158H123.047V33.9348Z"
        fill="#828282"
      />
      <path
        d="M49.0799 6.84387C50.1907 6.84387 51.1133 6.99347 51.8852 7.25526C52.6383 7.51705 53.2408 7.92843 53.7114 8.41462C54.1633 8.9008 54.5022 9.49918 54.6905 10.2285C54.8787 10.939 54.9729 11.7244 54.9729 12.5659V21.2985C54.4645 21.4107 53.6738 21.5416 52.6195 21.6911C51.584 21.8407 50.3978 21.9342 49.0799 21.9342C48.2139 21.9342 47.4231 21.8594 46.7077 21.6724C45.9922 21.5229 45.3709 21.2424 44.8626 20.8871C44.3543 20.5318 43.9589 20.0456 43.6576 19.4659C43.3752 18.8862 43.2246 18.157 43.2246 17.2968C43.2246 16.474 43.3752 15.7822 43.6953 15.2025C44.0154 14.6415 44.4672 14.174 45.0132 13.8C45.578 13.4447 46.2182 13.1829 46.9336 13.0147C47.6679 12.8651 48.4398 12.7716 49.2117 12.7716C49.5694 12.7716 49.9648 12.7903 50.379 12.8277C50.7744 12.8838 51.2262 12.9586 51.6781 13.0708V12.5098C51.6781 12.1358 51.6216 11.7431 51.5275 11.4065C51.4333 11.0512 51.2639 10.752 51.0568 10.4902C50.812 10.2285 50.5108 10.0228 50.1531 9.87317C49.7765 9.72357 49.287 9.64877 48.7222 9.64877C47.9314 9.64877 47.2348 9.70487 46.5947 9.79837C45.9357 9.91056 45.4086 10.0415 45.0132 10.1724L44.6178 7.47965C45.0509 7.33005 45.6533 7.18046 46.4629 7.03087C47.2725 6.91867 48.1574 6.84387 49.0799 6.84387ZM49.3435 19.2602C50.379 19.2602 51.1698 19.2041 51.6969 19.1106V15.4082C51.5086 15.3521 51.2451 15.296 50.9062 15.2586C50.5485 15.2025 50.1719 15.1838 49.7389 15.1838C49.3812 15.1838 48.9858 15.2212 48.6281 15.2586C48.2515 15.3147 47.9126 15.4082 47.6114 15.5578C47.3101 15.7074 47.0466 15.9131 46.8771 16.1748C46.6888 16.4366 46.6135 16.7732 46.6135 17.1659C46.6135 17.9513 46.8771 18.4936 47.3666 18.7927C47.8373 19.1106 48.5151 19.2602 49.3435 19.2602Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M88.9564 12.5472C88.5234 14.1554 88.0716 15.7261 87.5821 17.2595C87.0925 18.7928 86.603 20.2327 86.1323 21.5977H83.3647C83.007 20.7562 82.5928 19.7839 82.1974 18.6993C81.7832 17.6148 81.3879 16.4741 80.9737 15.2399C80.5595 14.0058 80.1641 12.7155 79.7499 11.3505C79.3357 9.98542 78.9403 8.60167 78.5449 7.19922H82.1033C82.2727 7.98459 82.461 8.80736 82.6869 9.68623C82.9129 10.5651 83.1576 11.5001 83.3836 12.4163C83.6283 13.3513 83.8731 14.2489 84.1366 15.1277C84.4002 16.0066 84.6638 16.8294 84.9274 17.5587C85.191 16.7172 85.4922 15.857 85.737 14.9407C86.0006 14.0432 86.2641 13.1269 86.5089 12.248C86.7537 11.3505 86.9796 10.4716 87.1867 9.61143C87.3938 8.75126 87.5821 7.94719 87.7515 7.19922H90.3308C90.4815 7.94719 90.6886 8.75126 90.8957 9.61143C91.1028 10.4716 91.3099 11.3318 91.5546 12.248C91.7806 13.1456 92.0253 14.0619 92.3077 14.9407C92.5713 15.8383 92.8537 16.7172 93.1173 17.5587C93.362 16.8294 93.6068 16.0253 93.8704 15.1277C94.134 14.2489 94.3787 13.3326 94.6423 12.4163C94.9059 11.5001 95.1318 10.5838 95.3577 9.68623C95.5837 8.80736 95.772 7.96589 95.9414 7.19922H99.4056C99.0291 8.60167 98.6149 10.0041 98.2007 11.3505C97.7865 12.7155 97.3911 13.9871 96.9769 15.2399C96.5627 16.4741 96.1673 17.6335 95.7531 18.6993C95.3389 19.7839 94.9624 20.7375 94.5482 21.5977H91.8182C91.3475 20.2327 90.8392 18.7928 90.3308 17.2595C89.8602 15.7261 89.3707 14.1554 88.9564 12.5472Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M99.7246 14.4733C99.7246 13.2017 99.9129 12.0985 100.289 11.1448C100.666 10.1911 101.174 9.40576 101.796 8.76998C102.417 8.1342 103.151 7.66672 103.961 7.34883C104.77 7.03094 105.599 6.88135 106.446 6.88135C108.442 6.88135 110.004 7.49843 111.115 8.71388C112.226 9.94804 112.791 11.7806 112.791 14.2302C112.791 14.4172 112.791 14.6229 112.772 14.8473C112.753 15.0904 112.753 15.296 112.715 15.4643H103.151C103.245 16.6237 103.66 17.5026 104.375 18.1383C105.109 18.7741 106.164 19.092 107.557 19.092C108.366 19.092 109.119 19.0172 109.797 18.8676C110.475 18.718 111.002 18.5684 111.398 18.4001L111.849 21.1302C111.661 21.2237 111.416 21.3172 111.077 21.4107C110.757 21.5042 110.4 21.5977 109.967 21.6725C109.552 21.7473 109.101 21.8221 108.63 21.8782C108.159 21.9343 107.651 21.953 107.161 21.953C105.881 21.953 104.789 21.766 103.829 21.392C102.888 21.018 102.116 20.4945 101.494 19.8213C100.892 19.1481 100.421 18.3627 100.139 17.4465C99.8752 16.5489 99.7246 15.5391 99.7246 14.4733ZM109.458 12.9773C109.458 12.5098 109.402 12.0798 109.27 11.6684C109.138 11.257 108.95 10.883 108.705 10.5838C108.442 10.2846 108.14 10.0415 107.802 9.87324C107.444 9.70495 107.011 9.61145 106.502 9.61145C105.994 9.61145 105.523 9.70495 105.147 9.89194C104.77 10.0789 104.431 10.3407 104.149 10.6586C103.885 10.9765 103.678 11.3318 103.528 11.7432C103.377 12.1546 103.264 12.5659 103.226 12.9773H109.458Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M120.003 21.8595C119.005 21.8408 118.176 21.7286 117.517 21.5229C116.858 21.3172 116.331 21.0367 115.955 20.644C115.578 20.2514 115.296 19.8026 115.145 19.2416C114.995 18.6806 114.9 18.0448 114.9 17.3343V0.785345L118.252 0.224365V16.7172C118.252 17.1286 118.289 17.4652 118.346 17.7269C118.402 17.9887 118.515 18.2318 118.704 18.4188C118.854 18.6058 119.099 18.7554 119.381 18.8676C119.664 18.9611 120.04 19.0359 120.473 19.1107L120.003 21.8595Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M126.874 21.8595C125.876 21.8408 125.047 21.7286 124.388 21.5229C123.711 21.3172 123.202 21.0367 122.826 20.644C122.449 20.2514 122.167 19.8026 122.016 19.2416C121.866 18.6806 121.771 18.0448 121.771 17.3343V0.785345L125.123 0.224365V16.7172C125.123 17.1286 125.16 17.4652 125.217 17.7269C125.273 17.9887 125.386 18.2318 125.575 18.4188C125.725 18.6058 125.97 18.7554 126.252 18.8676C126.535 18.9611 126.911 19.0359 127.344 19.1107L126.874 21.8595Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M77.0764 10.8082C76.9258 9.98541 76.6245 9.29354 76.2103 8.71386C75.7961 8.13418 75.2125 7.6854 74.497 7.36751C73.7628 7.04962 72.8402 6.90002 71.7106 6.90002C71.2587 6.90002 70.8257 6.95612 70.3927 7.04962C69.9408 7.14312 69.5454 7.25531 69.1689 7.40491C68.7924 7.5545 68.4346 7.6854 68.1146 7.87239C67.8133 8.02198 67.5686 8.17158 67.3803 8.32117C67.192 8.17158 66.9284 8.04068 66.646 7.87239C66.326 7.72279 65.9682 7.5545 65.5917 7.40491C65.2152 7.25531 64.801 7.14312 64.3679 7.04962C63.9349 6.95612 63.483 6.90002 63.05 6.90002C61.9204 6.90002 60.9978 7.04962 60.2636 7.36751C59.5293 7.6854 58.9645 8.15288 58.5503 8.71386C58.1361 9.27484 57.8537 10.0041 57.6842 10.8082C57.5336 11.631 57.4395 12.5098 57.4395 13.4822V21.579H60.7907V14.0058C60.7907 12.5098 60.979 11.4066 61.3555 10.7521C61.7133 10.0789 62.4475 9.76102 63.483 9.76102C64.029 9.76102 64.5374 9.85452 65.0081 10.0228C65.2716 10.1163 65.4976 10.2098 65.6858 10.3033V13.6318V21.579H67.3615H69.0371V13.6318V10.3033C69.2254 10.2098 69.4513 10.1163 69.7149 10.0228C70.1856 9.85452 70.7127 9.76102 71.2399 9.76102C72.2942 9.76102 73.0097 10.0976 73.3674 10.7521C73.7251 11.4253 73.9322 12.5098 73.9322 14.0058V21.579H77.2835V13.4822C77.3212 12.5285 77.2458 11.631 77.0764 10.8082Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M126.385 1.83328C126.385 1.53409 126.46 1.2536 126.611 0.973107C126.761 0.711317 126.968 0.505624 127.251 0.33733C127.533 0.169036 127.816 0.0942383 128.117 0.0942383C128.418 0.0942383 128.701 0.169036 128.983 0.31863C129.247 0.468225 129.454 0.673918 129.623 0.954408C129.774 1.2162 129.868 1.51539 129.868 1.81458C129.868 2.11377 129.793 2.39426 129.642 2.65605C129.491 2.91784 129.284 3.12353 129.021 3.29183C128.757 3.44142 128.456 3.53492 128.136 3.53492C127.816 3.53492 127.533 3.46012 127.251 3.29183C126.968 3.12353 126.78 2.93654 126.63 2.65605C126.46 2.41296 126.385 2.13247 126.385 1.83328ZM126.63 1.83328C126.63 2.09507 126.686 2.33816 126.837 2.58125C126.968 2.80564 127.157 2.99264 127.383 3.12353C127.609 3.25443 127.853 3.31052 128.117 3.31052C128.38 3.31052 128.625 3.25443 128.851 3.10483C129.077 2.97394 129.265 2.78694 129.397 2.56255C129.529 2.33816 129.604 2.09507 129.604 1.81458C129.604 1.55279 129.548 1.3097 129.397 1.0853C129.265 0.860911 129.077 0.673918 128.851 0.543022C128.625 0.412127 128.38 0.33733 128.098 0.33733C127.834 0.33733 127.59 0.393428 127.364 0.543022C127.138 0.673918 126.95 0.860911 126.818 1.0853C126.705 1.3097 126.63 1.57149 126.63 1.83328ZM128.813 1.42189C128.813 1.55279 128.776 1.64628 128.719 1.73978C128.663 1.83328 128.569 1.88937 128.456 1.94547L129.002 2.84304H128.625L128.155 2.03897H127.853V2.84304H127.514V0.823512H128.117C128.38 0.823512 128.569 0.879611 128.682 0.973107C128.757 1.0666 128.813 1.2162 128.813 1.42189ZM127.816 1.75848H128.079C128.211 1.75848 128.305 1.72108 128.362 1.66498C128.437 1.60888 128.475 1.51539 128.475 1.42189C128.475 1.3097 128.437 1.2349 128.38 1.1788C128.324 1.1227 128.211 1.104 128.06 1.104H127.816V1.75848Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M18.5934 35C18.161 35 17.7286 34.8306 17.409 34.5106L12.6525 29.805C11.9945 29.1462 11.9757 28.0733 12.6337 27.3957C13.2917 26.7369 14.3633 26.7181 15.0401 27.3769L18.6122 30.9155L31.3212 18.4738C34.5549 15.0104 34.5361 9.40128 31.3024 5.95675C29.9488 4.52623 28.2568 3.66039 26.3767 3.45334C23.9515 3.18983 21.5451 4.09331 19.7966 5.9191C19.477 6.25791 19.0258 6.44613 18.5746 6.44613C18.1046 6.44613 17.6722 6.25791 17.3526 5.9191C15.9426 4.43212 14.0625 3.52863 12.0885 3.4157C9.73845 3.28394 7.48241 4.20625 5.82798 5.95675C3.62834 8.29075 2.83872 11.7165 3.77874 14.8975C4.04195 15.801 3.53434 16.7421 2.63192 17.0056C1.7295 17.2691 0.789488 16.7609 0.526283 15.8574C-0.75214 11.5282 0.33828 6.84141 3.36513 3.62275C5.69637 1.13816 8.94883 -0.179417 12.2577 0.0276312C14.5513 0.159389 16.7322 1.02523 18.5558 2.45575C20.887 0.611132 23.7823 -0.254708 26.7339 0.0652764C29.3848 0.347615 31.8664 1.60873 33.7465 3.60392C38.2022 8.34722 38.2022 16.0645 33.7465 20.8078C33.7277 20.8266 33.7089 20.8454 33.6901 20.8642L19.7778 34.4918C19.4394 34.8306 19.007 35 18.5934 35Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M9.87628 26.25C9.38367 26.25 8.91001 26.0596 8.53108 25.6978L4.02184 21.2801C3.26399 20.5375 3.24504 19.3189 3.98395 18.5382C4.72286 17.7765 5.93543 17.7575 6.71223 18.5001L9.87628 21.6039L20.8083 10.8263C21.5662 10.0837 22.7788 10.0837 23.5366 10.8454C24.2755 11.607 24.2755 12.8257 23.5177 13.5874L11.2404 25.6787C10.8425 26.0596 10.3499 26.25 9.87628 26.25Z"
        fill="url(#paint8_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="49.0987"
          y1="6.84387"
          x2="49.0987"
          y2="21.9342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25ABFD" />
          <stop offset="1" stopColor="#0469BD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="88.9753"
          y1="7.19922"
          x2="88.9753"
          y2="21.5977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25ABFD" />
          <stop offset="1" stopColor="#0469BD" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="106.258"
          y1="6.88135"
          x2="106.258"
          y2="21.953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25ABFD" />
          <stop offset="1" stopColor="#0469BD" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="117.687"
          y1="0.224365"
          x2="117.687"
          y2="21.8595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25ABFD" />
          <stop offset="1" stopColor="#0469BD" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="124.558"
          y1="0.224365"
          x2="124.558"
          y2="21.8595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25ABFD" />
          <stop offset="1" stopColor="#0469BD" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="67.3662"
          y1="6.90002"
          x2="67.3662"
          y2="21.579"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25ABFD" />
          <stop offset="1" stopColor="#0469BD" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="128.126"
          y1="0.0942383"
          x2="128.126"
          y2="3.53492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25ABFD" />
          <stop offset="1" stopColor="#0469BD" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="18.5526"
          y1="33.5135"
          x2="18.5526"
          y2="2.15342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0469BD" />
          <stop offset="1" stopColor="#25ABFD" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="3"
          y1="18"
          x2="24"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EF9C23" />
          <stop offset="1" stopColor="#FCD54E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
