import {
  fetchSubscriptionInformation,
  fetchAccountInformation,
  getAuthInfo,
} from '.';

export default async function fetchCurrentUserInfo() {
  let data = {};

  /**
   * Retrieve any authenticated user
   */
  const { currentUser, idToken, jwt, session, accessToken, jwtToken } =
    await getAuthInfo();


  if (!currentUser) {
    console.log('User not logged in');
    return {};
  }

  /**
   * Retrieve Account Information (recurly, salesforce)
   */
  try {
    data = await fetchAccountInformation({ currentUser, jwt });
  } catch (err) {
    console.log('Account Error:', err.toString());
  }

  const user = {
    currentUser,
    idToken,
    jwt,
    data,
    session,
    accessToken,
    jwtToken,
  };

  /**
   * Fetch Subscription information
   */
  const subscriptionData = await fetchSubscriptionInformation(user);

  return { user, subscriptionData };
}
