import { isCodeFreemium } from '../../../utils';
import {
  CHECKOUT_BILLING_SECTION_KEY,
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
} from '..';
import onCheckoutValidateSection from './onCheckoutValidateSection';

export default function onCheckoutSetSubscriptionTier(state, payload) {
  const { tier } = payload;
  const checkout = { ...state.checkout };
  const { availableSubscriptions } = checkout;
  const { billingPeriod, providerAccounts, _lastProviderAccountsAmount } =
    checkout[CHECKOUT_PLAN_SECTION_KEY];

  const freePlan = tier === 'starter';

  const subscription = freePlan
    ? availableSubscriptions.find((subscription) =>
        isCodeFreemium(subscription.code)
      )
    : availableSubscriptions.find(
        (subscription) => subscription.code.includes('awn-pp') // was billingPeriod
      );
  console.log('availableSubscriptions', availableSubscriptions);
  console.log('onCheckoutSetSubscriptionTier', {
    freePlan,
    subscription,
    billingPeriod,
  });
  if (freePlan) {
    checkout[CHECKOUT_PLAN_SECTION_KEY]._lastProviderAccountsAmount =
      providerAccounts;
    checkout[CHECKOUT_PLAN_SECTION_KEY].providerAccounts = 1;
  } else {
    checkout[CHECKOUT_PLAN_SECTION_KEY].providerAccounts =
      _lastProviderAccountsAmount || providerAccounts;
  }
  checkout[CHECKOUT_PLAN_SECTION_KEY].freePlan = freePlan;
  checkout[CHECKOUT_PLAN_SECTION_KEY].subscription = subscription;
  // eslint-disable-next-line no-param-reassign
  state = onCheckoutValidateSection(
    { ...state, checkout },
    { sectionKey: CHECKOUT_CONTACT_SECTION_KEY }
  );
  // eslint-disable-next-line no-param-reassign
  state = onCheckoutValidateSection(
    { ...state, checkout },
    { sectionKey: CHECKOUT_BILLING_SECTION_KEY }
  );
  return state;
}
