import React from 'react';

export default function DashboardIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1" fill="white">
        <rect width="9.26187" height="9.26187" rx="1" />
      </mask>
      <rect
        width="9.26187"
        height="9.26187"
        rx="1"
        stroke="white"
        strokeWidth="3"
        mask="url(#path-1-inside-1)"
      />
      <mask id="path-2-inside-2" fill="white">
        <rect y="11.735" width="9.26187" height="9.26187" rx="1" />
      </mask>
      <rect
        y="11.735"
        width="9.26187"
        height="9.26187"
        rx="1"
        stroke="white"
        strokeWidth="3"
        mask="url(#path-2-inside-2)"
      />
      <mask id="path-3-inside-3" fill="white">
        <rect x="11.7363" width="9.26187" height="9.26187" rx="1" />
      </mask>
      <rect
        x="11.7363"
        width="9.26187"
        height="9.26187"
        rx="1"
        stroke="white"
        strokeWidth="3"
        mask="url(#path-3-inside-3)"
      />
      <mask id="path-4-inside-4" fill="white">
        <rect x="11.7363" y="11.735" width="9.26187" height="9.26187" rx="1" />
      </mask>
      <rect
        x="11.7363"
        y="11.735"
        width="9.26187"
        height="9.26187"
        rx="1"
        stroke="white"
        strokeWidth="3"
        mask="url(#path-4-inside-4)"
      />
    </svg>
  );
}
