import { navigate } from 'gatsby'
import React, { useContext, useEffect } from 'react'
import { StateContext } from '../../lib/providers'

export default function RequiresAuth({children}) {
  const state = useContext(StateContext)
  const {user, authCheckDeffered} = state || {}

  useEffect(()=>{
    if(window){
      if(user === undefined && !authCheckDeffered) navigate('/signin')
    }
  },[user, authCheckDeffered])

  return <>
  {children}
  </>
}
