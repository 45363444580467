import React from 'react';

export default function Quote() {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71497 0.0879974C2.85897 2.104 0.842969 5.408 0.842969 9.048C0.842969 12.016 2.63497 13.752 4.70697 13.752C6.66697 13.752 8.12297 12.184 8.12297 10.336C8.12297 8.488 6.83497 7.144 5.15497 7.144C4.81897 7.144 4.37097 7.2 4.25897 7.256C4.53897 5.352 6.33097 3.112 8.12297 1.992L5.71497 0.0879974ZM15.347 0.0879974C12.547 2.104 10.531 5.408 10.531 9.048C10.531 12.016 12.323 13.752 14.395 13.752C16.299 13.752 17.811 12.184 17.811 10.336C17.811 8.488 16.467 7.144 14.787 7.144C14.451 7.144 14.059 7.2 13.947 7.256C14.227 5.352 15.963 3.112 17.755 1.992L15.347 0.0879974Z"
        fill="#007AC9"
      />
    </svg>
  );
}
