import React from 'react';

export default function AccountIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.6233" cy="8.25" r="2.75" stroke="white" />
      <path
        d="M10.75 12.8125H14.5C15.8807 12.8125 17 13.9318 17 15.3125V17.5H8.25V15.3125C8.25 13.9318 9.36929 12.8125 10.75 12.8125Z"
        stroke="white"
      />
      <circle cx="12.5" cy="12.5" r="11.75" stroke="white" strokeWidth="1.5" />
    </svg>
  );
}
