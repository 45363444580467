import React from 'react';

export default function MinusIcon() {
  return (
    <svg
      width="12"
      height="2"
      viewBox="0 0 12 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.6C0 0.268629 0.268629 0 0.6 0H11.4C11.7314 0 12 0.268629 12 0.6C12 0.931371 11.7314 1.2 11.4 1.2H0.6C0.268629 1.2 0 0.931371 0 0.6Z"
        fill="#4F4F4F"
      />
    </svg>
  );
}
