import {
  CHECKOUT_BILLING_SECTION_KEY,
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
  CHECKOUT_SUMMARY_SECTION_KEY,
} from '..';
import {
  ValidateCheckoutBillingInformation,
  ValidateCheckoutContactInformation,
  ValidateCheckoutPlan,
  ValidatePracticeUsers,
} from '../../../validators';
import onCheckoutSetCurrentSection from './onCheckoutSetCurrentSection';

export default function onCheckoutReviseSection(state, payload) {
  const { currentSection } = state.checkout;
  const checkout = { ...state.checkout };
  const { sectionKey } = payload;
  console.log('onCheckoutReviseSection', { sectionKey, currentSection });
  /**
   * Checks Validation of currentSection (set status "incomplete" || "complete")
   */
  if (currentSection) {
    let validation;
    let field;
    switch (currentSection) {
      case CHECKOUT_PLAN_SECTION_KEY:
        field = checkout[CHECKOUT_PLAN_SECTION_KEY];
        validation = ValidateCheckoutPlan(state, field);
        break;
      case CHECKOUT_CONTACT_SECTION_KEY:
        field = checkout[CHECKOUT_CONTACT_SECTION_KEY];
        validation = ValidateCheckoutContactInformation(state, field);
        break;
      case CHECKOUT_BILLING_SECTION_KEY:
        field = checkout[CHECKOUT_BILLING_SECTION_KEY];
        validation = ValidateCheckoutBillingInformation(state, field, {
          checkToken: true,
        });
        break;
      case CHECKOUT_PRACTICE_USER_SECTION_KEY:
        field = checkout[CHECKOUT_PRACTICE_USER_SECTION_KEY];
        validation = ValidatePracticeUsers(state, field);
        break;
      default:
        break;
    }

    if (currentSection !== CHECKOUT_SUMMARY_SECTION_KEY) {
      checkout[currentSection].validation = validation;
      checkout[currentSection].status = validation.isValid
        ? 'completed'
        : 'incomplete';
    }
  }

  // eslint-disable-next-line no-return-assign
  return onCheckoutSetCurrentSection(
    state,
    // eslint-disable-next-line no-param-reassign
    (payload = {
      previousSection: currentSection,
      currentSection: sectionKey,
    })
  );
}
