import {
  CHECKOUT_BILLING_SECTION_KEY,
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_SIGNATURE_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
} from '..';
import {
  ValidateCheckoutBillingInformation,
  ValidateCheckoutContactInformation,
  ValidateSignature,
  ValidateCheckoutPlan,
  ValidatePracticeUsers,
} from '../../../validators';

export default function onCheckoutValidateSection(state, payload) {
  const { sectionKey } = payload;
  const checkout = { ...state.checkout };
  let validation;
  let field;

  if (!sectionKey) return state;

  switch (sectionKey) {
    case CHECKOUT_PLAN_SECTION_KEY:
      field = checkout[CHECKOUT_PLAN_SECTION_KEY];
      validation = ValidateCheckoutPlan(state, field);
      break;
    case CHECKOUT_CONTACT_SECTION_KEY:
      field = checkout[CHECKOUT_CONTACT_SECTION_KEY];
      validation = ValidateCheckoutContactInformation(state, field);
      break;
    case CHECKOUT_SIGNATURE_SECTION_KEY:
      field = checkout[CHECKOUT_SIGNATURE_SECTION_KEY];
      validation = ValidateSignature(state, field);
      break;
    case CHECKOUT_BILLING_SECTION_KEY:
      field = checkout[CHECKOUT_BILLING_SECTION_KEY];
      validation = ValidateCheckoutBillingInformation(state, field, {
        checkToken: true,
      });
      break;
    case CHECKOUT_PRACTICE_USER_SECTION_KEY:
      field = checkout[CHECKOUT_PRACTICE_USER_SECTION_KEY];
      validation = ValidatePracticeUsers(state, field);
      break;
    default:
      break;
  }

  checkout[sectionKey].validation = validation;

  if (checkout[sectionKey].status === 'editing') {
    checkout[sectionKey].status = validation.isValid ? 'completed' : 'editing';
  } else {
    checkout[sectionKey].status = validation.isValid
      ? 'completed'
      : 'incomplete';
  }

  return { ...state };
}
