import { Auth } from 'aws-amplify';

export default async function getAuthInfo() {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const idToken = currentUser.getSignInUserSession().getIdToken();
    const jwt = idToken.getJwtToken();
    const accessToken = currentSession.getAccessToken();
    const jwtToken = accessToken.getJwtToken();
    return { currentUser, currentSession, idToken, jwt, accessToken, jwtToken };
  } catch (err) {
    return {};
  }
}
