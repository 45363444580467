export default function generateNewUserObj(fields = {}) {
  const {
    FirstName: firstName = '',
    LastName: lastName = '',
    Email: email = '',
    NPI__c: npi = '',
    Title: title = '',
    Specialty__c: specialty = '',
    Id: id = '',
  } = { ...fields };

  // eslint-disable-next-line no-param-reassign
  delete fields.FirstName;
  // eslint-disable-next-line no-param-reassign
  delete fields.LastName;
  // eslint-disable-next-line no-param-reassign
  delete fields.Email;
  // eslint-disable-next-line no-param-reassign
  delete fields.NPI__c;
  // eslint-disable-next-line no-param-reassign
  delete fields.Title;
  // eslint-disable-next-line no-param-reassign
  delete fields.Specialty__c;
  // eslint-disable-next-line no-param-reassign
  delete fields.Id;

  return {
    id,
    firstName,
    lastName,
    email,
    npi,
    title,
    specialty,
    ...fields,
  };
}
