import React from 'react';

export default function GooglePlay() {
  return (
    <svg
      id="artwork"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 135 40"
    >
      <defs>
        <style>
          {
            '.cls-1{fill:#a6a6a6;}.cls-10,.cls-2,.cls-3{fill:#fff;}.cls-2{stroke:#fff;stroke-miterlimit:10;stroke-width:0.2px;}.cls-4{fill:url(#linear-gradient);}.cls-5{fill:url(#linear-gradient-2);}.cls-6{fill:url(#linear-gradient-3);}.cls-7{fill:url(#linear-gradient-4);}.cls-8{opacity:0.2;}.cls-10,.cls-8,.cls-9{isolation:isolate;}.cls-9{opacity:0.12;}.cls-10{opacity:0.25;}'
          }
        </style>
        <linearGradient
          id="linear-gradient"
          x1="21.8"
          y1="193.289"
          x2="5.017"
          y2="176.506"
          gradientTransform="matrix(1, 0, 0, -1, 0, 202)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#00a0ff" />
          <stop offset="0.007" stopColor="#00a1ff" />
          <stop offset="0.26" stopColor="#00beff" />
          <stop offset="0.512" stopColor="#00d2ff" />
          <stop offset="0.76" stopColor="#00dfff" />
          <stop offset="1" stopColor="#00e3ff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="33.835"
          y1="181.997"
          x2="9.638"
          y2="181.997"
          gradientTransform="matrix(1, 0, 0, -1, 0, 202)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ffe000" />
          <stop offset="0.409" stopColor="#ffbd00" />
          <stop offset="0.775" stopColor="orange" />
          <stop offset="1" stopColor="#ff9c00" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="24.827"
          y1="179.702"
          x2="2.069"
          y2="156.944"
          gradientTransform="matrix(1, 0, 0, -1, 0, 202)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#ff3a44" />
          <stop offset="1" stopColor="#c31162" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="7.297"
          y1="201.822"
          x2="17.46"
          y2="191.66"
          gradientTransform="matrix(1, 0, 0, -1, 0, 202)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#32a071" />
          <stop offset="0.069" stopColor="#2da771" />
          <stop offset="0.476" stopColor="#15cf74" />
          <stop offset="0.801" stopColor="#06e775" />
          <stop offset="1" stopColor="#00f076" />
        </linearGradient>
      </defs>
      <rect width="135" height="40" rx="5" />
      <path
        className="cls-1"
        d="M130,.8A4.205,4.205,0,0,1,134.2,5V35a4.205,4.205,0,0,1-4.2,4.2H5A4.205,4.205,0,0,1,.8,35V5A4.205,4.205,0,0,1,5,.8H130m0-.8H5A5.015,5.015,0,0,0,0,5V35a5.015,5.015,0,0,0,5,5H130a5.015,5.015,0,0,0,5-5V5a5.015,5.015,0,0,0-5-5Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-2"
        d="M47.419,10.245a2.705,2.705,0,0,1-.746,2,2.909,2.909,0,0,1-2.2.887A3.089,3.089,0,0,1,41.352,10a3.091,3.091,0,0,1,3.117-3.134,3.09,3.09,0,0,1,1.232.252,2.477,2.477,0,0,1,.938.67l-.527.528a2.024,2.024,0,0,0-1.643-.712,2.32,2.32,0,0,0-2.33,2.4,2.359,2.359,0,0,0,4.006,1.727,1.889,1.889,0,0,0,.5-1.215H44.469V9.792h2.908A2.572,2.572,0,0,1,47.419,10.245Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-2"
        d="M52.028,7.739H49.3v1.9h2.463v.721H49.3v1.9h2.732V13h-3.5V7h3.5Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-2"
        d="M55.279,13h-.772V7.739H52.832V7h4.123v.738H55.279Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-2"
        d="M59.938,13V7h.771v6Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-2"
        d="M64.129,13h-.772V7.739H61.681V7H65.8v.738H64.129Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-2"
        d="M73.61,12.226a3.117,3.117,0,0,1-4.4,0A3.068,3.068,0,0,1,68.326,10a3.066,3.066,0,0,1,.884-2.224,3.1,3.1,0,0,1,4.4,0A3.07,3.07,0,0,1,74.493,10,3.075,3.075,0,0,1,73.61,12.226Zm-3.831-.5a2.308,2.308,0,0,0,3.26,0A2.347,2.347,0,0,0,73.706,10a2.346,2.346,0,0,0-.667-1.722,2.308,2.308,0,0,0-3.26,0A2.353,2.353,0,0,0,69.113,10,2.354,2.354,0,0,0,69.779,11.724Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-2"
        d="M75.575,13V7h.939l2.916,4.668h.033l-.033-1.156V7H80.2v6h-.8L76.346,8.108h-.033l.033,1.156V13Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-3"
        d="M68.136,21.753a4.253,4.253,0,1,0,4.269,4.253A4.192,4.192,0,0,0,68.136,21.753Zm0,6.831a2.584,2.584,0,1,1,2.4-2.578A2.46,2.46,0,0,1,68.136,28.584Zm-9.313-6.831a4.253,4.253,0,1,0,4.269,4.253A4.192,4.192,0,0,0,58.823,21.753Zm0,6.831a2.584,2.584,0,1,1,2.4-2.578A2.461,2.461,0,0,1,58.823,28.584ZM47.744,23.058v1.8h4.318a3.772,3.772,0,0,1-.983,2.272,4.422,4.422,0,0,1-3.335,1.321,4.8,4.8,0,0,1,0-9.6A4.6,4.6,0,0,1,51,20.142l1.273-1.273a6.293,6.293,0,0,0-4.527-1.82,6.606,6.606,0,1,0,0,13.21,6.037,6.037,0,0,0,4.607-1.852,5.961,5.961,0,0,0,1.563-4.221,5.84,5.84,0,0,0-.1-1.128Zm45.308,1.4a3.952,3.952,0,0,0-3.641-2.707A4.042,4.042,0,0,0,85.4,26.006a4.161,4.161,0,0,0,4.221,4.253,4.23,4.23,0,0,0,3.544-1.885l-1.45-.966a2.428,2.428,0,0,1-2.094,1.176A2.164,2.164,0,0,1,87.558,27.3l5.688-2.352Zm-5.8,1.417A2.333,2.333,0,0,1,89.476,23.4a1.645,1.645,0,0,1,1.578.9ZM82.629,30H84.5V17.5H82.629Zm-3.061-7.3H79.5a2.945,2.945,0,0,0-2.239-.951,4.257,4.257,0,0,0,0,8.506,2.9,2.9,0,0,0,2.239-.966h.065v.612c0,1.627-.87,2.5-2.272,2.5a2.354,2.354,0,0,1-2.143-1.514l-1.627.676a4.054,4.054,0,0,0,3.77,2.514c2.191,0,4.044-1.289,4.044-4.431V22.011H79.568Zm-2.143,5.88a2.587,2.587,0,0,1,0-5.155A2.4,2.4,0,0,1,79.7,26.022,2.377,2.377,0,0,1,77.425,28.584ZM101.806,17.5H97.335V30H99.2V25.265h2.605a3.888,3.888,0,1,0,0-7.765Zm.048,6.025H99.2V19.24h2.653a2.143,2.143,0,1,1,0,4.285Zm11.532-1.795a3.5,3.5,0,0,0-3.329,1.914l1.656.692a1.768,1.768,0,0,1,1.705-.917,1.8,1.8,0,0,1,1.962,1.608v.129a4.126,4.126,0,0,0-1.946-.483c-1.785,0-3.6.981-3.6,2.815a2.888,2.888,0,0,0,3.1,2.75,2.633,2.633,0,0,0,2.38-1.222h.064v.965h1.8V25.188C117.182,22.968,115.525,21.73,113.386,21.73Zm-.225,6.851c-.611,0-1.464-.3-1.464-1.061,0-.965,1.062-1.335,1.978-1.335a3.323,3.323,0,0,1,1.7.418A2.262,2.262,0,0,1,113.161,28.581ZM123.744,22l-2.14,5.42h-.064L119.321,22H117.31l3.329,7.576-1.9,4.213h1.946L125.818,22Zm-16.807,8H108.8V17.5h-1.865Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-4"
        d="M10.436,7.539a2,2,0,0,0-.463,1.405V31.06a2,2,0,0,0,.463,1.405l.074.072L22.9,20.149v-.293L10.51,7.467Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-5"
        d="M27.028,24.28,22.9,20.149v-.293l4.13-4.13.093.053,4.893,2.78c1.4.794,1.4,2.093,0,2.888l-4.893,2.78Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-6"
        d="M27.122,24.226,22.9,20,10.436,32.465a1.627,1.627,0,0,0,2.078.062l14.608-8.3"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-7"
        d="M27.122,15.778l-14.608-8.3a1.627,1.627,0,0,0-2.078.061L22.9,20Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-8"
        d="M27.029,24.133,12.514,32.381a1.666,1.666,0,0,1-2,.01h0l-.074.075h0l.074.072h0a1.665,1.665,0,0,0,2-.01l14.608-8.3Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-9"
        d="M10.436,32.32a2,2,0,0,1-.463-1.405v.146a2,2,0,0,0,.463,1.405l.074-.075Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-9"
        d="M32.015,21.3l-4.986,2.833.093.093,4.893-2.78A1.755,1.755,0,0,0,33.063,20h0A1.861,1.861,0,0,1,32.015,21.3Z"
        transform="translate(0 -0.001)"
      />
      <path
        className="cls-10"
        d="M12.514,7.625l19.5,11.08A1.859,1.859,0,0,1,33.063,20h0a1.751,1.751,0,0,0-1.048-1.443l-19.5-11.08c-1.4-.795-2.541-.135-2.541,1.466v.146C9.973,7.49,11.116,6.831,12.514,7.625Z"
        transform="translate(0 -0.001)"
      />
    </svg>
  );
}
