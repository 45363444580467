const calcTierPricing = (quantity, isFree) => {
  let price = 0;
  if (!isFree) {
    for (let i = 0; i < quantity; i++) {
      if (i < 10) price += 49.0;
      else if (i < 20) price += 44.0;
      else if (i >= 20) price += 39.0;
    }
  }
  const money = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
  return { price, money };
};
export default calcTierPricing;
