import {
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
} from '..';
import onCheckoutValidateSection from './onCheckoutValidateSection';

export default function onCheckoutSetUsers(state, payload) {
  const { users } = payload;
  const checkout = { ...state.checkout };
  const { freePlan } = checkout[CHECKOUT_PLAN_SECTION_KEY];
  const { contactInfo } = checkout[CHECKOUT_CONTACT_SECTION_KEY];
  if (freePlan) {
    users[0].firstName = contactInfo.firstName;
    users[0].lastName = contactInfo.lastName;
    users[0].email = contactInfo.email;
  }
  checkout[CHECKOUT_PRACTICE_USER_SECTION_KEY].users = users;
  // eslint-disable-next-line no-return-assign,no-param-reassign
  return (state = onCheckoutValidateSection(
    { ...state, checkout },
    { sectionKey: CHECKOUT_PRACTICE_USER_SECTION_KEY }
  ));
}
