import {
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
} from '..';
import onCheckoutValidateSection from './onCheckoutValidateSection';

export default function onCheckoutSetProviderAccounts(state, payload) {
  const checkout = { ...state.checkout };
  const { value } = payload;
  if (!value || value > 75) return state;
  checkout[CHECKOUT_PLAN_SECTION_KEY].providerAccounts = value;
  // eslint-disable-next-line no-return-assign,no-param-reassign
  return (state = onCheckoutValidateSection(
    { ...state, checkout },
    { sectionKey: CHECKOUT_PRACTICE_USER_SECTION_KEY }
  ));
}
