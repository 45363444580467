import React from 'react';

export default function CaretIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
    >
      <path
        d="M9.48764 1.48348L5.47137 5.55198C5.31029 5.71251 5.09551 5.80661 4.87 5.80661C4.64449 5.80661 4.42971 5.71804 4.26863 5.55198L0.252359 1.48348C0.0912789 1.31742 0 1.10154 0 0.869054C0 0.636568 0.0912789 0.420688 0.252359 0.254627C0.41344 0.0941013 0.628214 0 0.853727 0C1.07924 0 1.29401 0.088566 1.45509 0.254627L4.87 3.71424L8.28491 0.254627C8.44599 0.0941013 8.66076 0 8.88627 0C9.11179 0 9.32656 0.088566 9.48764 0.254627C9.64872 0.420688 9.74 0.636568 9.74 0.869054C9.74 1.10707 9.64872 1.32295 9.48764 1.48348Z"
        fill="#007AC9"
      />
    </svg>
  );
}
