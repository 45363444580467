import { CHECKOUT_CONTACT_SECTION_KEY, CHECKOUT_PLAN_SECTION_KEY } from '..';
import { isCodeFreemium } from '../../../utils';
import onCheckoutValidateSection from './onCheckoutValidateSection';

export default function onCheckoutSetSubscriptionPlan(state, payload) {
  const checkout = { ...state.checkout };
  const { subscription } = payload;
  if (!subscription) return state;

  console.log('Passed subscription', subscription);

  checkout[CHECKOUT_PLAN_SECTION_KEY].freePlan = !!isCodeFreemium(subscription.code);

  checkout[CHECKOUT_PLAN_SECTION_KEY].subscription = subscription;
  setTimeout(() => {
    const data = window.dataLayer || [];
    const eventName = 'begin_checkout';
    const item = {
      item_name: subscription.name,
      item_id: subscription.id,
      price: subscription.addOns[0].tiers[0].currencies[0].unitAmount,
      item_brand: '',
      item_category: '',
      item_variant: subscription.code,
      quantity: 1
    };

    data.push({
      event: eventName,
      ecommerce: {
        items: [item]
      }
    });
  }, 50);
  return onCheckoutValidateSection(
    { ...state, checkout },
    { sectionKey: CHECKOUT_CONTACT_SECTION_KEY }
  );
  // return {...state, checkout}
}
