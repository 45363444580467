import { CHECKOUT_PLAN_SECTION_KEY } from "../events/checkout"

export default function ValidatePracticeUsers(state, checkoutPracticeUsers) {
  const { checkout } = state
  const { providerAccounts } = checkout[CHECKOUT_PLAN_SECTION_KEY]
  const { skipUsersSetup, users } = checkoutPracticeUsers
  const validation = {
    isValid: false,
    error: "",
    fields: {},
    users: [],
  }

  /**
   * Handles validation of practice users
   */
  if (!skipUsersSetup) {
    users.forEach((user, index) => {
      const userValidationObject = validateUser(user, index)
      validation.users.push(userValidationObject)
    })
  }

  /**
   * Validation Check
   */
  const hasNoInvalidUsers = !validation.users.find(
    user => user.foundErrors.length
  )
  if (hasNoInvalidUsers && users.length === providerAccounts) {
    validation.isValid = true
  } else if (skipUsersSetup) {
      validation.isValid = true
    } else {
      validation.isValid = false
      validation.error = "Invalid user information"
    }

  console.log("ValidatePracticeUsers", {
    checkoutPracticeUsers,
    skipUsersSetup,
    validation,
  })
  return validation
}




// ================= VALIDATION FUNCTIONS ====================== //

const isNotEmpty = (value, foundErrors, field) => {
  if (!value || value.trim() === "" || value === "0") {
    foundErrors.push({ field, message: "This field is required" })
  }
  return foundErrors
}

const isEmail = (value, foundErrors, field) => {
  const emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  if (!emailCheck.test(value)) {
    foundErrors.push({ field, message: "A valid email must be provided" })
  }
  return foundErrors
}

const isProperLength = ({value, length}, foundErrors, field) => {
  if(value.length !== parseInt(length, 10)){
    foundErrors.push({ field, message: `An NPI number must be ${length} characters` })
  }
  return foundErrors
}

/**
 * Returns the validation of an individual user
 */
export function validateUser(user, index) {
  let foundErrors = [];
    const errorFields = {}

  const { firstName, lastName, email, npi } = user
  // Check first name
  foundErrors = isNotEmpty(firstName, foundErrors, "firstName")

  // Check last name
  foundErrors = isNotEmpty(lastName, foundErrors, "lastName")

  // Check email name
  foundErrors = isNotEmpty(email, foundErrors, "email")
  foundErrors = isEmail(email, foundErrors, "email")

  // Check npi if present
  if(npi){
    foundErrors = isProperLength({value: npi, length: 10}, foundErrors, "npi")
  }

  // Push found error for each required field
  foundErrors.forEach(error => {
    const { field, message } = error
    errorFields[field] = errorFields[field] || []
    errorFields[field].push(message)
  })

  // Create and return a validation object
  return {
    index,
    foundErrors,
    errorFields,
  }
}


export function filterValidUsers(users) {
  const validatedUsers = users.map((user, index) =>({user, validation: validateUser(user, index)}))
  return validatedUsers
    .filter(validatedUser => !validatedUser.validation.foundErrors.length)
    .map(validatedUser => validatedUser.user)
}
