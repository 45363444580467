const calcTotalAmount = (subtotal, couponDiscount) => {
  let price = subtotal - couponDiscount;
  price = price > 0 ? price : 0;
  const money = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
  return { price, money };
};
export default calcTotalAmount;
