import React from 'react';

export default function GettingStarted() {
  return (
    <svg
      width="176"
      height="99"
      viewBox="0 0 176 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9551 87.1831H158.771V5.6754H16.9551V87.1831ZM158.646 0.25647H17.0806C13.8968 0.25647 11.3164 2.84949 11.3164 6.0485V86.8198C11.3164 90.0089 13.8968 92.6125 17.0806 92.6125H158.646C161.83 92.6125 164.41 90.0089 164.41 86.8198V6.0485C164.41 2.84949 161.83 0.25647 158.646 0.25647V0.25647Z"
        fill="#007AC9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0546875 92.5738V95.0863C0.0546875 96.6487 3.5796 97.9138 7.92771 97.9138H167.796C172.145 97.9138 175.67 96.6487 175.67 95.0863V92.5738H0.0546875Z"
        fill="#007AC9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.1621 92.6125V93.1164C78.1621 93.4264 78.5513 93.6801 79.0319 93.6801H96.6944C97.1747 93.6801 97.5642 93.4264 97.5642 93.1164V92.6125H78.1621Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7071 88.8213H156.843C158.779 88.8213 160.349 87.244 160.349 85.2982V7.43698C160.349 5.49121 158.779 3.91385 156.843 3.91385H18.7071C16.7709 3.91385 15.2012 5.49121 15.2012 7.43698V85.2982C15.2012 87.244 16.7709 88.8213 18.7071 88.8213Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M95.0117 49.8523L84.1432 55.3132V44.3915L95.0117 49.8523Z"
        fill="#007AC9"
      />
      <path
        d="M87.8251 62.3594C94.699 62.3594 100.271 56.7598 100.271 49.8523C100.271 42.9448 94.699 37.3452 87.8251 37.3452C80.9513 37.3452 75.3789 42.9448 75.3789 49.8523C75.3789 56.7598 80.9513 62.3594 87.8251 62.3594Z"
        stroke="#007AC9"
        strokeWidth="2"
      />
    </svg>
  );
}
