import { CHECKOUT_PLAN_SECTION_KEY } from '..';
import {
  calcTierPricing,
  getAnnualDiscount,
  calcTotalAmount,
  isCodeFreemium,
  getCouponDiscount,
  getMoneyPrice,
} from '../../../utils';

export default function onCheckoutUpdatePaymentSummary(state, payload) {
  const checkout = { ...state.checkout };
  const { coupon } = checkout;
  const { providerAccounts, subscription, freePlan, billingPeriod } =
    checkout[CHECKOUT_PLAN_SECTION_KEY];

  const isFree = isCodeFreemium(subscription.code) || freePlan;
  const voidDiscount = isFree || billingPeriod !== 'annual';

  const planSubtotal = calcTierPricing(providerAccounts, isFree);
  const discount = getAnnualDiscount(planSubtotal.price, voidDiscount);
  const subtotal = getMoneyPrice(planSubtotal.price - discount.price);
  const couponDiscount = getCouponDiscount(coupon, subtotal);
  const total = calcTotalAmount(subtotal.price, couponDiscount.price);

  console.log('onCheckoutUpdatePaymentSummary', {
    planSubtotal,
    discount,
    subtotal,
    couponDiscount,
    total,
  });

  checkout.subtotal = subtotal;
  checkout.discount = discount;
  checkout.planSubtotal = planSubtotal;
  checkout.couponDiscount = couponDiscount;
  checkout.total = total;

  return { ...state, checkout };
}
