import React from 'react';

export default function UserIcon() {
  return (
    <svg
      width="18"
      height="24"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.99609" cy="6" r="5.25" stroke="white" strokeWidth="1.5" />
      <path
        d="M0.75 17.5C0.75 15.7051 2.20507 14.25 4 14.25H14C15.7949 14.25 17.25 15.7051 17.25 17.5V23.25H0.75V17.5Z"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}
