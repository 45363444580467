import React from 'react';

export default function Arrow() {
  return (
    <svg
      width="11"
      height="7"
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5786 1.65407L6.10052 6.1904C5.92092 6.36939 5.68144 6.47431 5.43 6.47431C5.17856 6.47431 4.93909 6.37556 4.75948 6.1904L0.281378 1.65407C0.101775 1.46891 0 1.22821 0 0.968986C0 0.709767 0.101775 0.469063 0.281378 0.283907C0.460981 0.104922 0.700452 0 0.951896 0C1.20334 0 1.44281 0.0987502 1.62241 0.283907L5.43 4.14134L9.23759 0.283907C9.41719 0.104922 9.65666 0 9.9081 0C10.1595 0 10.399 0.0987502 10.5786 0.283907C10.7582 0.469063 10.86 0.709767 10.86 0.968986C10.86 1.23438 10.7582 1.47508 10.5786 1.65407Z"
        fill="#4F4F4F"
      />
    </svg>
  );
}
