import React from 'react';

export default function CaretIcon2() {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.463 2.35119L8.38437 8.50882C8.14058 8.75177 7.81552 8.89419 7.47421 8.89419C7.13289 8.89419 6.80784 8.76015 6.56404 8.50882L0.485459 2.35119C0.241665 2.09986 0.103516 1.77312 0.103516 1.42126C0.103516 1.0694 0.241665 0.742664 0.485459 0.491332C0.729252 0.248378 1.05431 0.105957 1.39562 0.105957C1.73693 0.105957 2.06199 0.240001 2.30578 0.491332L7.47421 5.72741L12.6426 0.491332C12.8864 0.248378 13.2115 0.105957 13.5528 0.105957C13.8941 0.105957 14.2192 0.240001 14.463 0.491332C14.7067 0.742664 14.8449 1.0694 14.8449 1.42126C14.8449 1.7815 14.7067 2.10823 14.463 2.35119Z"
        fill="#4F4F4F"
      />
    </svg>
  );
}
