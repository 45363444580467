export default function ValidateSignature(
  state,
  checkoutSignature
) {
  const { signature } = checkoutSignature
  console.log(checkoutSignature);
  const validation = {
    isValid: true,
    error: "",
    fields: {},
  }

  // eslint-disable-next-line guard-for-in

  for (const key in signature) {
    if (key) {
      const value = signature[key]
      let foundErrors = []
      switch (key) {
        case "signature":
          foundErrors = isNotEmpty(value, foundErrors)
          break
        default:
      }

      if (foundErrors.length) {
        validation.isValid = false
        validation.fields[key] = foundErrors
      }
    }
  }

  return validation
}

export const isNotEmpty = (value, foundErrors) => {
  if (!value || value.trim() === "" || value === '0') {
    foundErrors.push("This field is required")
  }
  return foundErrors
}
