const getAnnualDiscount = (price, voidDiscount) => {
  const discount = voidDiscount ? 0 : (15 / 100) * price;

  const money = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(discount);
  return { price: discount, money };
};

export default getAnnualDiscount;
