import { CHECKOUT_PLAN_SECTION_KEY } from '..';

export default function onCheckoutSetSubscriptionBillingPeriod(state, payload) {
  const { billingPeriod, toggle } = payload;
  const checkout = { ...state.checkout };
  if (toggle) {
    const newBillingPeriod =
      checkout[CHECKOUT_PLAN_SECTION_KEY].billingPeriod === 'annual'
        ? 'monthly'
        : 'annual';
    console.log('onCheckoutSetSubscriptionBillingPeriod', { newBillingPeriod });

    checkout[CHECKOUT_PLAN_SECTION_KEY].billingPeriod = newBillingPeriod;
  } else {
    checkout[CHECKOUT_PLAN_SECTION_KEY].billingPeriod = billingPeriod;
  }
  return { ...state, checkout };
}
