export const CHECKOUT_INIT_CHECKOUT = 'checkout-initCheckout';
export const CHECKOUT_SET_SUBSCRIPTIONS = 'checkout-setSubscriptions';
export const CHECKOUT_SET_SUBSCRIPTION_ITEMS = 'checkout-setSubscriptionItems';
export const CHECKOUT_PROGRESS_TO_NEXT_SECTION =
  'checkout-progressToNextSection';
export const CHECKOUT_SET_SUBSCRIPTION_PLAN = 'checkout-setSubscriptionPlan';
export const CHECKOUT_REVISE_SECTION = 'checkout-reviseSection';
export const CHECKOUT_SET_CURRENT_SECTION = 'checkout-setCurrentSection';
export const CHECKOUT_TOGGLE_CONTACT_AS_BILLING_INFO =
  'checkout-toggleContactAsBilling';
export const CHECKOUT_SET_CONTACT_INFO = 'checkout-setContactInfo';
export const CHECKOUT_SET_SIGNATURE_INFO = 'checkout-setSignatureInfo';
export const CHECKOUT_SET_BILLING_INFO = 'checkout-setPaymentInfo';
export const CHECKOUT_SET_PROVIDER_ACCOUNTS = 'checkout-setProviderAccounts';
export const CHECKOUT_SET_SUBSCRIPTION_BILLING_PERIOD =
  'checkout-setSubscriptionBillingPeriod';
export const CHECKOUT_UPDATE_PAYMENT_SUMMARY = 'checkout-updatePaymentSummary';
export const CHECKOUT_SET_USER_INFO = 'checkout-setUserInfo';
export const CHECKOUT_CREATE_NEW_USER = 'checkout-createNewUser';
export const CHECKOUT_SET_SUBSCRIPTION_TIER = 'checkout-setSubscriptionTier';
export const CHECKOUT_TOGGLE_TOS = 'checkout-toggleTermsOfService';
export const CHECKOUT_SET_BILLING_CARD_BRAND = 'checkout-setBillingCardBrand';
export const CHECKOUT_SET_BILLING_CARD_LAST_FOUR =
  'checkout-setBillingCardLastFour';
export const CHECKOUT_SET_BILLING_CARD_VALIDATION =
  'checkout-setBillingCardValidation';
export const CHECKOUT_SET_BILLING_RECURLY_TOKEN =
  'checkout-setBillingRecurlyToken';
export const CHECKOUT_INIT_FLOW = 'checkout-initFlow';
export const CHECKOUT_SET_USERS = 'checkout-setUsers';
export const CHECKOUT_READY_PAGE = 'checkout-readyPage';
export const CHECKOUT_SET_CHECKOUT_ERROR = 'checkout-setCheckoutError';
export const CHECKOUT_CHECKOUT_COMPLETED = 'checkout-setCheckoutCompleted';
export const CHECKOUT_VALIDATE_SECTION = 'checkout-validateSection';
export const CHECKOUT_SUSPEND = 'checkout-suspend';
export const CHECKOUT_SET_COUPON = 'checkout-setCoupon';
