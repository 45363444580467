import AppReducer from './app';
import CheckoutReducer from './checkout';

export default function EventReducer(state, action) {
  const { type } = action;
  const namespace = type.split('-')[0];

  switch (namespace) {
    case 'app':
      return AppReducer(state, action);
    case 'checkout':
      return CheckoutReducer(state, action);
    default:
      return DefaultReducer(state, action);
  }
}

function DefaultReducer(state, action) {
  const { type, payload } = action;
  // console.log('[DefaultReducer]', {state, action, type, payload})
  switch (type) {
    case 'reset':
      return {};
    case 'suspend':
      return { ...state, ready: false };
    case 'ready':
      return { ...state, ready: true };
    case 'merge':
      return { ...state, ...payload };
    default:
      return state;
  }
}
