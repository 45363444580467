export default function throwEndpointError(
  err = {},
  res = {},
  exceptionMessage = 'exception',
  logToConsole = true
) {
  const { type, status, statusText } = res;
  const { headers } = res;
  const headerEntries = {};
  if (headers) {
    for (const [name, value] of headers.entries()) {
      headerEntries[name] = value;
    }
  }
  const { message, name } = err;
  const error = new Error(exceptionMessage);
  error.name = 'API Exception';

  if (logToConsole) {
    console.error('API Exception', {
      error,
      exceptionMessage,
      err,
      res,
      message,
      name,
      type,
      status,
      statusText,
      headers: headerEntries,
    });
  }

  throw error;
}
