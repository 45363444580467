export default function ValidateCheckoutPlan(state, checkoutPlan) {
  const { availableSubscriptions } = state.checkout;
  const { subscription } = checkoutPlan;
  const validation = {
    isValid: false,
    error: '',
    fields: {},
  };

  const isSet = subscription;
  const foundErrors = [];

  /**
   * Checks if the subscription is set
   */
  if (!isSet) {
    foundErrors.push('No checkout subscription has been set');
  }

  /**
   * Checks if the subscription is a valid subscription
   */
  const validAndExists =
    subscription &&
    availableSubscriptions &&
    availableSubscriptions.find((sub) => sub.code === subscription.code);
  if (!validAndExists) {
    foundErrors.push('The set subscription does not exist');
  }

  if (foundErrors.length) {
    validation.fields.subscription = foundErrors;
  }

  if (!Object.keys(validation.fields).length) {
    validation.isValid = true;
  } else {
    validation.isValid = false;
    validation.error = 'Invalid subscription plan information';
  }

  console.log('ValidateCheckoutPlan', {
    state,
    availableSubscriptions,
    checkoutPlan,
    validation,
  });

  return validation;
}
