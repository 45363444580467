import React from 'react';

export default function BillingIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="11.25"
        fill="#007AC9"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M15.2666 13.9033C15.2666 14.5641 15.0251 15.1087 14.542 15.5371C14.0589 15.9655 13.3617 16.2298 12.4502 16.3301V17.8135H11.541V16.3711C10.4108 16.3529 9.4834 16.1797 8.75879 15.8516V14.4092C9.15072 14.6006 9.60872 14.7624 10.1328 14.8945C10.6569 15.0221 11.1263 15.0882 11.541 15.0928V12.5361L10.9668 12.3242C10.2194 12.0371 9.67253 11.6953 9.32617 11.2988C8.98438 10.8978 8.81348 10.4033 8.81348 9.81543C8.81348 9.18652 9.05729 8.66927 9.54492 8.26367C10.0371 7.85807 10.7025 7.61198 11.541 7.52539V6.37695H12.4502V7.50488C13.3753 7.53678 14.2526 7.72363 15.082 8.06543L14.583 9.31641C13.8675 9.03385 13.1566 8.86523 12.4502 8.81055V11.2988L12.9697 11.4971C13.8356 11.8298 14.4349 12.1807 14.7676 12.5498C15.1003 12.9189 15.2666 13.3701 15.2666 13.9033ZM13.6533 14.0059C13.6533 13.7415 13.5599 13.5251 13.373 13.3564C13.1908 13.1833 12.8831 13.0169 12.4502 12.8574V15.0381C13.2523 14.915 13.6533 14.571 13.6533 14.0059ZM10.4199 9.80176C10.4199 10.0615 10.4997 10.278 10.6592 10.4512C10.8232 10.6243 11.1172 10.7952 11.541 10.9639V8.83789C11.1764 8.89258 10.8984 9.00423 10.707 9.17285C10.5156 9.34147 10.4199 9.55111 10.4199 9.80176Z"
        fill="white"
      />
    </svg>
  );
}
