import React from 'react';

export default function AmwellLogo1() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="35"
      viewBox="0 0 129 35"
      fill="none"
    >
      <path
        d="M44.7447 27.1631C45.6992 27.1631 46.4292 27.3343 46.9532 27.6957C47.4586 28.0571 47.7206 28.6087 47.7206 29.3506C47.7206 29.75 47.6458 30.0734 47.5147 30.3587C47.3837 30.6441 47.1778 30.8723 46.9158 31.0435C46.6538 31.2147 46.3356 31.3479 45.9613 31.443C45.5869 31.5191 45.1565 31.5761 44.6698 31.5761H44.0709V33.9348H42.873V27.3343C43.1538 27.2772 43.4533 27.2202 43.8089 27.2011C44.1458 27.1631 44.464 27.1631 44.7447 27.1631ZM44.8383 28.2093C44.5388 28.2093 44.2768 28.2283 44.0896 28.2473V30.5299H44.6698C45.25 30.5299 45.6992 30.4538 45.9987 30.2827C46.3169 30.1115 46.4666 29.8071 46.4666 29.3506C46.4666 29.1223 46.4292 28.9511 46.3356 28.799C46.2607 28.6468 46.1297 28.5327 45.9987 28.4566C45.849 28.3805 45.6805 28.3044 45.4746 28.2854C45.2688 28.2283 45.0629 28.2093 44.8383 28.2093Z"
        fill="white"
      />
      <path
        d="M50.6215 27.1631C51.576 27.1631 52.306 27.3343 52.8113 27.6957C53.3167 28.0571 53.56 28.5897 53.56 29.3125C53.56 30.2256 53.1108 30.8343 52.2311 31.1577C52.3434 31.3098 52.4931 31.481 52.6429 31.6903C52.7926 31.8995 52.9423 32.1278 53.1108 32.375C53.2605 32.6223 53.429 32.8696 53.56 33.1359C53.7097 33.4022 53.8407 33.6495 53.953 33.8968H52.6054C52.4931 33.6685 52.3621 33.4403 52.2124 33.193C52.0814 32.9647 51.9316 32.7365 51.7819 32.5082C51.6322 32.2799 51.5012 32.0707 51.3514 31.8805C51.2204 31.6903 51.0894 31.5 50.9584 31.3479C50.8648 31.3479 50.7899 31.3669 50.7338 31.3669C50.6776 31.3669 50.6028 31.3669 50.5466 31.3669H49.9664V33.8968H48.7686V27.2962C49.068 27.2392 49.3675 27.1821 49.7044 27.1631C50.0787 27.1631 50.3595 27.1631 50.6215 27.1631ZM50.7151 28.2093C50.453 28.2093 50.2284 28.2283 50.0038 28.2473V30.4158H50.5279C50.8274 30.4158 51.0707 30.3968 51.2953 30.3587C51.5199 30.3207 51.707 30.2636 51.8568 30.1875C52.0065 30.1115 52.1188 29.9973 52.1937 29.8452C52.2685 29.712 52.306 29.5218 52.306 29.3125C52.306 29.1033 52.2685 28.9321 52.1937 28.799C52.1188 28.6658 52.0065 28.5517 51.8568 28.4566C51.707 28.3805 51.5386 28.3044 51.3514 28.2854C51.1643 28.2283 50.9397 28.2093 50.7151 28.2093Z"
        fill="white"
      />
      <path
        d="M54.9082 27.2202H56.1061V33.9159H54.9082V27.2202Z"
        fill="white"
      />
      <path
        d="M59.5686 33.9348C59.1006 32.8886 58.6327 31.8044 58.1835 30.663C57.7343 29.5217 57.3226 28.3804 56.9482 27.2391H58.2771C58.4268 27.6957 58.5766 28.1522 58.745 28.6277C58.8948 29.1033 59.0632 29.5598 59.2129 30.0163C59.3627 30.4728 59.5311 30.9103 59.6809 31.3288C59.8306 31.7473 59.999 32.1467 60.1488 32.5082C60.2985 32.1467 60.4482 31.7663 60.598 31.3478C60.7477 30.9294 60.9161 30.4919 61.0846 30.0353C61.253 29.5788 61.4028 29.1223 61.5712 28.6467C61.721 28.1712 61.8894 27.7147 62.0204 27.2582H63.3119C62.9188 28.3995 62.507 29.5408 62.0766 30.6821C61.6461 31.8234 61.1782 32.9076 60.7103 33.9538H59.5686V33.9348Z"
        fill="white"
      />
      <path
        d="M68.0466 33.9348C67.953 33.6685 67.8595 33.4022 67.7659 33.1549C67.6723 32.8886 67.5787 32.6413 67.4851 32.356H64.6964C64.6028 32.6223 64.5092 32.8886 64.4156 33.1549C64.322 33.4212 64.2284 33.6685 64.1349 33.9348H62.8809C63.1242 33.212 63.3675 32.5652 63.5921 31.9565C63.8167 31.3478 64.0226 30.7772 64.2472 30.2446C64.453 29.712 64.6776 29.1794 64.8835 28.7038C65.0894 28.2093 65.314 27.7337 65.5386 27.2582H66.6803C66.9049 27.7337 67.1108 28.2283 67.3354 28.7038C67.5413 29.1984 67.7472 29.712 67.9718 30.2446C68.1776 30.7772 68.4022 31.3669 68.6268 31.9565C68.8514 32.5652 69.0947 33.231 69.3381 33.9348H68.0466ZM66.1001 28.5326C65.9504 28.875 65.7819 29.2935 65.5948 29.7881C65.4076 30.2826 65.2204 30.8152 65.0145 31.3859H67.1857C66.9798 30.8152 66.7926 30.2826 66.5867 29.7881C66.3996 29.2745 66.2311 28.856 66.1001 28.5326Z"
        fill="white"
      />
      <path
        d="M74.2232 27.2202V28.2664H72.2018V33.9159H70.9853V28.2664H68.9639V27.2202H74.2232Z"
        fill="white"
      />
      <path
        d="M75.1221 33.9348V27.2391H79.3707V28.2663H76.3386V29.9212H79.0338V30.9294H76.3386V32.8886H79.5953V33.9158H75.1221V33.9348Z"
        fill="white"
      />
      <path
        d="M84.7797 27.1631C85.7342 27.1631 86.4642 27.3343 86.9882 27.6957C87.4936 28.0571 87.7556 28.6087 87.7556 29.3506C87.7556 29.75 87.6808 30.0734 87.5497 30.3587C87.4187 30.6441 87.2128 30.8723 86.9508 31.0435C86.6888 31.2147 86.3706 31.3479 85.9963 31.443C85.6219 31.5191 85.1915 31.5761 84.7048 31.5761H84.1059V33.9348H82.9268V27.3343C83.2075 27.2772 83.507 27.2202 83.8626 27.2011C84.1995 27.1631 84.4989 27.1631 84.7797 27.1631ZM84.8733 28.2093C84.5738 28.2093 84.3118 28.2283 84.1246 28.2473V30.5299H84.7048C85.285 30.5299 85.7342 30.4538 86.0337 30.2827C86.3519 30.1115 86.5016 29.8071 86.5016 29.3506C86.5016 29.1223 86.4642 28.9511 86.3706 28.799C86.2957 28.6468 86.1647 28.5327 86.0337 28.4566C85.884 28.3805 85.7155 28.3044 85.5096 28.2854C85.3225 28.2283 85.0979 28.2093 84.8733 28.2093Z"
        fill="white"
      />
      <path
        d="M90.6752 27.1631C91.6297 27.1631 92.3597 27.3343 92.865 27.6957C93.3704 28.0571 93.6137 28.5897 93.6137 29.3125C93.6137 30.2256 93.1645 30.8343 92.2848 31.1577C92.3971 31.3098 92.5469 31.481 92.6966 31.6903C92.8463 31.8995 92.9961 32.1278 93.1645 32.375C93.3142 32.6223 93.4827 32.8696 93.6137 33.1359C93.7634 33.4022 93.8944 33.6495 94.0067 33.8968H92.6592C92.5469 33.6685 92.4158 33.4403 92.2661 33.193C92.1351 32.9647 91.9854 32.7365 91.8356 32.5082C91.6859 32.2799 91.5549 32.0707 91.4051 31.8805C91.2741 31.6903 91.1431 31.5 91.0121 31.3479C90.9185 31.3479 90.8437 31.3669 90.7875 31.3669C90.7314 31.3669 90.6565 31.3669 90.6003 31.3669H90.0201V33.8968H88.8223V27.2962C89.1217 27.2392 89.4212 27.1821 89.7581 27.1631C90.1137 27.1631 90.4132 27.1631 90.6752 27.1631ZM90.7501 28.2093C90.488 28.2093 90.2634 28.2283 90.0388 28.2473V30.4158H90.5629C90.8624 30.4158 91.1057 30.3968 91.3303 30.3587C91.5549 30.3207 91.742 30.2636 91.8918 30.1875C92.0415 30.1115 92.1538 29.9973 92.2287 29.8452C92.3035 29.712 92.341 29.5218 92.341 29.3125C92.341 29.1033 92.3035 28.9321 92.2287 28.799C92.1538 28.6658 92.0415 28.5517 91.8918 28.4566C91.742 28.3805 91.5736 28.3044 91.3864 28.2854C91.1993 28.2283 90.9934 28.2093 90.7501 28.2093Z"
        fill="white"
      />
      <path
        d="M99.5652 33.9348C99.4716 33.6685 99.378 33.4022 99.2844 33.1549C99.1908 32.8886 99.0973 32.6413 99.0037 32.356H96.2149C96.1213 32.6223 96.0278 32.8886 95.9342 33.1549C95.8406 33.4212 95.747 33.6685 95.6534 33.9348H94.3994C94.6427 33.212 94.886 32.5652 95.1106 31.9565C95.3352 31.3478 95.5411 30.7772 95.7657 30.2446C95.9716 29.712 96.1962 29.1794 96.4021 28.7038C96.608 28.2093 96.8326 27.7337 97.0572 27.2582H98.1989C98.4235 27.7337 98.6294 28.2283 98.8539 28.7038C99.0598 29.1984 99.2657 29.712 99.4903 30.2446C99.6962 30.7772 99.9208 31.3669 100.145 31.9565C100.37 32.5652 100.613 33.231 100.857 33.9348H99.5652ZM97.6187 28.5326C97.4689 28.875 97.3005 29.2935 97.1133 29.7881C96.9261 30.2826 96.739 30.8152 96.5331 31.3859H98.7042C98.4983 30.8152 98.3112 30.2826 98.1053 29.7881C97.9181 29.2745 97.7497 28.856 97.6187 28.5326Z"
        fill="white"
      />
      <path
        d="M104.45 34.068C103.964 34.068 103.515 33.9919 103.121 33.8397C102.728 33.6876 102.392 33.4593 102.13 33.1549C101.849 32.8506 101.643 32.4892 101.493 32.0517C101.343 31.6142 101.269 31.1196 101.269 30.568C101.269 30.0164 101.343 29.5028 101.512 29.0843C101.68 28.6468 101.905 28.2854 102.204 27.981C102.504 27.6767 102.841 27.4484 103.234 27.2963C103.627 27.1441 104.057 27.068 104.507 27.068C104.787 27.068 105.031 27.087 105.255 27.1251C105.48 27.1631 105.667 27.2202 105.835 27.2582C106.004 27.3153 106.135 27.3723 106.247 27.4294C106.359 27.4865 106.434 27.5245 106.472 27.5626L106.116 28.5517C105.966 28.4566 105.742 28.3615 105.461 28.2854C105.18 28.1903 104.881 28.1522 104.544 28.1522C104.263 28.1522 104.001 28.2093 103.739 28.3044C103.496 28.3995 103.271 28.5517 103.103 28.7609C102.916 28.9702 102.785 29.2174 102.672 29.5218C102.579 29.8261 102.523 30.1685 102.523 30.568C102.523 30.9294 102.56 31.2528 102.635 31.5381C102.71 31.8424 102.841 32.0897 103.009 32.299C103.178 32.5082 103.384 32.6794 103.646 32.7935C103.908 32.9077 104.207 32.9647 104.563 32.9647C104.993 32.9647 105.33 32.9267 105.592 32.8316C105.854 32.7365 106.06 32.6604 106.21 32.5843L106.528 33.5734C106.453 33.6305 106.341 33.6685 106.21 33.7256C106.079 33.7827 105.929 33.8207 105.742 33.8778C105.573 33.9348 105.367 33.9729 105.143 33.9919C104.956 34.068 104.712 34.068 104.45 34.068Z"
        fill="white"
      />
      <path
        d="M112.33 27.2202V28.2664H110.308V33.9159H109.092V28.2664H107.07V27.2202H112.33Z"
        fill="white"
      />
      <path
        d="M113.247 27.2202H114.445V33.9159H113.247V27.2202Z"
        fill="white"
      />
      <path
        d="M118.955 34.068C118.469 34.068 118.019 33.9919 117.626 33.8397C117.233 33.6876 116.896 33.4593 116.634 33.1549C116.354 32.8506 116.148 32.4892 115.998 32.0517C115.848 31.6142 115.773 31.1196 115.773 30.568C115.773 30.0164 115.848 29.5028 116.017 29.0843C116.185 28.6468 116.41 28.2854 116.709 27.981C117.009 27.6767 117.346 27.4484 117.739 27.2963C118.132 27.1441 118.562 27.068 119.011 27.068C119.292 27.068 119.535 27.087 119.76 27.1251C119.985 27.1631 120.172 27.2202 120.34 27.2582C120.509 27.3153 120.64 27.3723 120.752 27.4294C120.864 27.4865 120.939 27.5245 120.977 27.5626L120.621 28.5517C120.471 28.4566 120.247 28.3615 119.966 28.2854C119.685 28.1903 119.386 28.1522 119.049 28.1522C118.768 28.1522 118.506 28.2093 118.244 28.3044C118.001 28.3995 117.776 28.5517 117.608 28.7609C117.42 28.9702 117.289 29.2174 117.177 29.5218C117.084 29.8261 117.027 30.1685 117.027 30.568C117.027 30.9294 117.065 31.2528 117.14 31.5381C117.215 31.8424 117.346 32.0897 117.514 32.299C117.683 32.5082 117.888 32.6794 118.15 32.7935C118.412 32.9077 118.712 32.9647 119.068 32.9647C119.498 32.9647 119.835 32.9267 120.097 32.8316C120.359 32.7365 120.565 32.6604 120.715 32.5843L121.033 33.5734C120.958 33.6305 120.846 33.6685 120.715 33.7256C120.584 33.7827 120.434 33.8207 120.247 33.8778C120.078 33.9348 119.872 33.9729 119.648 33.9919C119.442 34.068 119.217 34.068 118.955 34.068Z"
        fill="white"
      />
      <path
        d="M122.101 33.9348V27.2391H126.331V28.2663H123.298V29.9212H125.994V30.9294H123.298V32.8886H126.555V33.9158H122.101V33.9348Z"
        fill="white"
      />
      <path
        d="M48.7028 6.84387C49.8051 6.84387 50.7206 6.99347 51.4865 7.25526C52.2338 7.51705 52.8317 7.92843 53.2987 8.41462C53.7471 8.9008 54.0834 9.49918 54.2702 10.2285C54.4571 10.939 54.5505 11.7244 54.5505 12.5659V21.2985C54.046 21.4107 53.2614 21.5416 52.2152 21.6911C51.1876 21.8407 50.0106 21.9342 48.7028 21.9342C47.8434 21.9342 47.0588 21.8594 46.3488 21.6724C45.6389 21.5229 45.0224 21.2424 44.518 20.8871C44.0135 20.5318 43.6212 20.0456 43.3223 19.4659C43.042 18.8862 42.8926 18.157 42.8926 17.2968C42.8926 16.474 43.042 15.7822 43.3596 15.2025C43.6772 14.6415 44.1256 14.174 44.6674 13.8C45.2279 13.4447 45.8631 13.1829 46.573 13.0147C47.3017 12.8651 48.0676 12.7716 48.8336 12.7716C49.1886 12.7716 49.5809 12.7903 49.9919 12.8277C50.3843 12.8838 50.8327 12.9586 51.281 13.0708V12.5098C51.281 12.1358 51.225 11.7431 51.1316 11.4065C51.0382 11.0512 50.87 10.752 50.6645 10.4902C50.4216 10.2285 50.1227 10.0228 49.7677 9.87317C49.3941 9.72357 48.9084 9.64877 48.3479 9.64877C47.5632 9.64877 46.872 9.70487 46.2368 9.79837C45.5829 9.91056 45.0598 10.0415 44.6674 10.1724L44.2751 7.47965C44.7048 7.33005 45.3026 7.18046 46.106 7.03087C46.9093 6.91867 47.7874 6.84387 48.7028 6.84387ZM48.9644 19.2602C49.9919 19.2602 50.7766 19.2041 51.2997 19.1106V15.4082C51.1129 15.3521 50.8513 15.296 50.515 15.2586C50.1601 15.2025 49.7864 15.1838 49.3567 15.1838C49.0018 15.1838 48.6094 15.2212 48.2545 15.2586C47.8808 15.3147 47.5445 15.4082 47.2456 15.5578C46.9467 15.7074 46.6851 15.9131 46.517 16.1748C46.3302 16.4366 46.2554 16.7732 46.2554 17.1659C46.2554 17.9513 46.517 18.4936 47.0027 18.7927C47.4698 19.1106 48.1424 19.2602 48.9644 19.2602Z"
        fill="white"
      />
      <path
        d="M88.2728 12.5472C87.8432 14.1554 87.3948 15.7261 86.909 17.2595C86.4233 18.7928 85.9375 20.2327 85.4705 21.5977H82.7241C82.3692 20.7562 81.9582 19.7839 81.5658 18.6993C81.1548 17.6148 80.7625 16.4741 80.3515 15.2399C79.9404 14.0058 79.5481 12.7155 79.1371 11.3505C78.7261 9.98542 78.3337 8.60167 77.9414 7.19922H81.4724C81.6405 7.98459 81.8274 8.80736 82.0516 9.68623C82.2758 10.5651 82.5186 11.5001 82.7428 12.4163C82.9857 13.3513 83.2286 14.2489 83.4901 15.1277C83.7517 16.0066 84.0132 16.8294 84.2748 17.5587C84.5363 16.7172 84.8353 15.857 85.0781 14.9407C85.3397 14.0432 85.6012 13.1269 85.8441 12.248C86.087 11.3505 86.3112 10.4716 86.5167 9.61143C86.7222 8.75126 86.909 7.94719 87.0772 7.19922H89.6367C89.7861 7.94719 89.9916 8.75126 90.1971 9.61143C90.4027 10.4716 90.6082 11.3318 90.851 12.248C91.0752 13.1456 91.3181 14.0619 91.5983 14.9407C91.8599 15.8383 92.1401 16.7172 92.4017 17.5587C92.6446 16.8294 92.8874 16.0253 93.149 15.1277C93.4105 14.2489 93.6534 13.3326 93.915 12.4163C94.1765 11.5001 94.4007 10.5838 94.6249 9.68623C94.8491 8.80736 95.0359 7.96589 95.2041 7.19922H98.6417C98.268 8.60167 97.857 10.0041 97.446 11.3505C97.035 12.7155 96.6426 13.9871 96.2316 15.2399C95.8206 16.4741 95.4283 17.6335 95.0172 18.6993C94.6062 19.7839 94.2326 20.7375 93.8216 21.5977H91.1126C90.6455 20.2327 90.1411 18.7928 89.6367 17.2595C89.1696 15.7261 88.6839 14.1554 88.2728 12.5472Z"
        fill="white"
      />
      <path
        d="M98.959 14.4733C98.959 13.2017 99.1458 12.0985 99.5195 11.1448C99.8931 10.1911 100.398 9.40576 101.014 8.76998C101.631 8.1342 102.359 7.66672 103.163 7.34883C103.966 7.03094 104.788 6.88135 105.629 6.88135C107.609 6.88135 109.16 7.49843 110.262 8.71388C111.364 9.94804 111.925 11.7806 111.925 14.2302C111.925 14.4172 111.925 14.6229 111.906 14.8473C111.887 15.0904 111.887 15.296 111.85 15.4643H102.359C102.453 16.6237 102.864 17.5026 103.574 18.1383C104.302 18.7741 105.348 19.092 106.731 19.092C107.534 19.092 108.282 19.0172 108.954 18.8676C109.627 18.718 110.15 18.5684 110.542 18.4001L110.991 21.1302C110.804 21.2237 110.561 21.3172 110.225 21.4107C109.907 21.5042 109.552 21.5977 109.122 21.6725C108.711 21.7473 108.263 21.8221 107.796 21.8782C107.329 21.9343 106.824 21.953 106.339 21.953C105.068 21.953 103.985 21.766 103.032 21.392C102.098 21.018 101.332 20.4945 100.715 19.8213C100.117 19.1481 99.6502 18.3627 99.37 17.4465C99.1084 16.5489 98.959 15.5391 98.959 14.4733ZM108.618 12.9773C108.618 12.5098 108.562 12.0798 108.431 11.6684C108.3 11.257 108.113 10.883 107.871 10.5838C107.609 10.2846 107.31 10.0415 106.974 9.87324C106.619 9.70495 106.189 9.61145 105.685 9.61145C105.18 9.61145 104.713 9.70495 104.34 9.89194C103.966 10.0789 103.63 10.3407 103.349 10.6586C103.088 10.9765 102.882 11.3318 102.733 11.7432C102.583 12.1546 102.471 12.5659 102.434 12.9773H108.618Z"
        fill="white"
      />
      <path
        d="M119.08 21.8595C118.089 21.8408 117.267 21.7286 116.613 21.5229C115.96 21.3172 115.436 21.0367 115.063 20.644C114.689 20.2514 114.409 19.8026 114.259 19.2416C114.11 18.6806 114.017 18.0448 114.017 17.3343V0.785345L117.342 0.224365V16.7172C117.342 17.1286 117.379 17.4652 117.436 17.7269C117.492 17.9887 117.604 18.2318 117.79 18.4188C117.94 18.6058 118.183 18.7554 118.463 18.8676C118.743 18.9611 119.117 19.0359 119.547 19.1107L119.08 21.8595Z"
        fill="white"
      />
      <path
        d="M125.899 21.8595C124.909 21.8408 124.087 21.7286 123.433 21.5229C122.76 21.3172 122.256 21.0367 121.882 20.644C121.509 20.2514 121.228 19.8026 121.079 19.2416C120.929 18.6806 120.836 18.0448 120.836 17.3343V0.785345L124.161 0.224365V16.7172C124.161 17.1286 124.199 17.4652 124.255 17.7269C124.311 17.9887 124.423 18.2318 124.61 18.4188C124.759 18.6058 125.002 18.7554 125.282 18.8676C125.563 18.9611 125.936 19.0359 126.366 19.1107L125.899 21.8595Z"
        fill="white"
      />
      <path
        d="M76.4839 10.8082C76.3345 9.98541 76.0356 9.29354 75.6245 8.71386C75.2135 8.13418 74.6344 7.6854 73.9244 7.36751C73.1958 7.04962 72.2804 6.90002 71.1594 6.90002C70.711 6.90002 70.2813 6.95612 69.8516 7.04962C69.4033 7.14312 69.0109 7.25531 68.6373 7.40491C68.2636 7.5545 67.9086 7.6854 67.591 7.87239C67.2921 8.02198 67.0493 8.17158 66.8624 8.32117C66.6756 8.17158 66.414 8.04068 66.1338 7.87239C65.8162 7.72279 65.4612 7.5545 65.0876 7.40491C64.7139 7.25531 64.3029 7.14312 63.8732 7.04962C63.4435 6.95612 62.9951 6.90002 62.5654 6.90002C61.4445 6.90002 60.529 7.04962 59.8004 7.36751C59.0718 7.6854 58.5113 8.15288 58.1003 8.71386C57.6893 9.27484 57.4091 10.0041 57.2409 10.8082C57.0915 11.631 56.998 12.5098 56.998 13.4822V21.579H60.3235V14.0058C60.3235 12.5098 60.5104 11.4066 60.884 10.7521C61.239 10.0789 61.9676 9.76102 62.9951 9.76102C63.5369 9.76102 64.0414 9.85452 64.5084 10.0228C64.77 10.1163 64.9942 10.2098 65.181 10.3033V13.6318V21.579H66.8437H68.5065V13.6318V10.3033C68.6933 10.2098 68.9175 10.1163 69.1791 10.0228C69.6461 9.85452 70.1692 9.76102 70.6923 9.76102C71.7386 9.76102 72.4485 10.0976 72.8035 10.7521C73.1584 11.4253 73.364 12.5098 73.364 14.0058V21.579H76.6894V13.4822C76.7268 12.5285 76.6521 11.631 76.4839 10.8082Z"
        fill="white"
      />
      <path
        d="M125.413 1.83254C125.413 1.53335 125.488 1.25286 125.637 0.972375C125.787 0.710584 125.992 0.504891 126.272 0.336597C126.553 0.168303 126.833 0.0935059 127.132 0.0935059C127.431 0.0935059 127.711 0.168303 127.991 0.317898C128.253 0.467493 128.458 0.673185 128.626 0.953675C128.776 1.21547 128.869 1.51466 128.869 1.81384C128.869 2.11303 128.795 2.39352 128.645 2.65532C128.496 2.91711 128.29 3.1228 128.029 3.29109C127.767 3.44069 127.468 3.53418 127.151 3.53418C126.833 3.53418 126.553 3.45939 126.272 3.29109C125.992 3.1228 125.805 2.93581 125.656 2.65532C125.488 2.41222 125.413 2.13173 125.413 1.83254ZM125.656 1.83254C125.656 2.09434 125.712 2.33743 125.861 2.58052C125.992 2.80491 126.179 2.9919 126.403 3.1228C126.627 3.25369 126.87 3.30979 127.132 3.30979C127.393 3.30979 127.636 3.25369 127.86 3.1041C128.085 2.9732 128.272 2.78621 128.402 2.56182C128.533 2.33743 128.608 2.09433 128.608 1.81384C128.608 1.55205 128.552 1.30896 128.402 1.08457C128.272 0.860179 128.085 0.673185 127.86 0.54229C127.636 0.411395 127.393 0.336597 127.113 0.336597C126.852 0.336597 126.609 0.392695 126.385 0.54229C126.16 0.673185 125.974 0.860179 125.843 1.08457C125.731 1.30896 125.656 1.57075 125.656 1.83254ZM127.823 1.42116C127.823 1.55205 127.786 1.64555 127.73 1.73905C127.674 1.83254 127.58 1.88864 127.468 1.94474L128.01 2.84231H127.636L127.169 2.03824H126.87V2.84231H126.534V0.82278H127.132C127.393 0.82278 127.58 0.878878 127.692 0.972375C127.767 1.06587 127.823 1.21547 127.823 1.42116ZM126.833 1.75775H127.095C127.225 1.75775 127.319 1.72035 127.375 1.66425C127.449 1.60815 127.487 1.51466 127.487 1.42116C127.487 1.30896 127.449 1.23417 127.393 1.17807C127.337 1.12197 127.225 1.10327 127.076 1.10327H126.833V1.75775Z"
        fill="white"
      />
      <path
        d="M18.4504 35C18.0213 35 17.5922 34.8306 17.2751 34.5106L12.5552 29.805C11.9022 29.1462 11.8836 28.0733 12.5365 27.3957C13.1895 26.7369 14.2528 26.7181 14.9244 27.3769L18.469 30.9155L31.0803 18.4738C34.2891 15.0104 34.2704 9.40128 31.0616 5.95675C29.7184 4.52623 28.0394 3.66039 26.1738 3.45334C23.7673 3.18983 21.3793 4.09331 19.6443 5.9191C19.3272 6.25791 18.8795 6.44613 18.4317 6.44613C17.9653 6.44613 17.5362 6.25791 17.2191 5.9191C15.8199 4.43212 13.9544 3.52863 11.9955 3.4157C9.66354 3.28394 7.42485 4.20625 5.78315 5.95675C3.60043 8.29075 2.81689 11.7165 3.74967 14.8975C4.01085 15.801 3.50715 16.7421 2.61168 17.0056C1.7162 17.2691 0.783415 16.7609 0.522235 15.8574C-0.746354 11.5282 0.335677 6.84141 3.33925 3.62275C5.65256 1.13816 8.88 -0.179417 12.1634 0.0276312C14.4394 0.159389 16.6035 1.02523 18.4131 2.45575C20.7264 0.611132 23.5994 -0.254708 26.5283 0.0652764C29.1588 0.347615 31.6213 1.60873 33.4869 3.60392C37.9083 8.34722 37.9083 16.0645 33.4869 20.8078C33.4682 20.8266 33.4496 20.8454 33.4309 20.8642L19.6257 34.4918C19.2899 34.8306 18.8608 35 18.4504 35Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M9.80041 26.25C9.3116 26.25 8.84158 26.0596 8.46557 25.6978L3.99101 21.2801C3.23898 20.5375 3.22018 19.3189 3.95341 18.5382C4.68663 17.7765 5.88988 17.7575 6.6607 18.5001L9.80041 21.6039L20.6484 10.8263C21.4004 10.0837 22.6037 10.0837 23.3557 10.8454C24.0889 11.607 24.0889 12.8257 23.3369 13.5874L11.1541 25.6787C10.7592 26.0596 10.2704 26.25 9.80041 26.25Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="18.4099"
          y1="33.5135"
          x2="18.4099"
          y2="2.15342"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F4A817" />
          <stop offset="1" stopColor="#F8C230" />
        </linearGradient>
      </defs>
    </svg>
  );
}
