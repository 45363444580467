import { CHECKOUT_PLAN_SECTION_KEY } from '..';
import onCheckoutSetCurrentSection from './onCheckoutSetCurrentSection';

export default function onCheckoutInitFlow(state, payload) {
  const { checkout } = state;
  const planSection = checkout[CHECKOUT_PLAN_SECTION_KEY];
  const fieldIsIncompleted =
    planSection.status !== 'completed' || !planSection.validation.isValid;
  if (!fieldIsIncompleted) return state;
  return onCheckoutSetCurrentSection(state, {
    currentSection: CHECKOUT_PLAN_SECTION_KEY,
  });
}
