import React from 'react';

export default function HelpIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="11.25"
        fill="#007AC9"
        stroke="white"
        strokeWidth="1.5"
      />
      <rect
        x="2.74609"
        y="5.82886"
        width="4.36364"
        height="21.8182"
        transform="rotate(-45 2.74609 5.82886)"
        fill="white"
      />
      <rect
        x="18.1719"
        y="2.74335"
        width="4.36364"
        height="21.8182"
        transform="rotate(45 18.1719 2.74335)"
        fill="white"
      />
      <circle
        cx="12.0014"
        cy="12"
        r="4.70455"
        fill="#007AC9"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
}
