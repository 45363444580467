import {
  onCheckoutProgressToNextSection,
  onCheckoutSetSubscriptionPlan,
  onCheckoutSetSubscriptions,
  onCheckoutReviseSection,
  onCheckoutInitCheckout,
  onCheckoutToggleContactAsBilling,
  onCheckoutSetContactInfo,
  onCheckoutSetSignatureInfo,
  onCheckoutSetCurrentSection,
  onCheckoutSetBillingInfo,
  onCheckoutSetSubscriptionItems,
  onCheckoutSetSubscriptionBillingPeriod,
  onCheckoutUpdatePaymentSummary,
  onCheckoutSetUserInfo,
  onCheckoutSetProviderAccounts,
  onCheckoutToggleTermsOfService,
  onCheckoutSetBillingCardBrand,
  onCheckoutSetBillingCardLastFour,
  onCheckoutCreateNewUser,
  onCheckoutSetSubscriptionTier,
  onCheckoutSetBillingCardValidation,
  onCheckoutSetBillingRecurlyToken,
  onCheckoutInitFlow,
  onCheckoutSetUsers,
  onCheckoutPageReady,
  onCheckoutCompleted,
  onCheckoutSetCheckoutError,
  onCheckoutValidateSection,
  onCheckoutSuspension,
  onCheckoutSetCoupon,
} from './actions';
import {
  CHECKOUT_CHECKOUT_COMPLETED,
  CHECKOUT_CREATE_NEW_USER,
  CHECKOUT_INIT_CHECKOUT,
  CHECKOUT_INIT_FLOW,
  CHECKOUT_PROGRESS_TO_NEXT_SECTION,
  CHECKOUT_READY_PAGE,
  CHECKOUT_REVISE_SECTION,
  CHECKOUT_SET_BILLING_CARD_BRAND,
  CHECKOUT_SET_BILLING_CARD_LAST_FOUR,
  CHECKOUT_SET_BILLING_CARD_VALIDATION,
  CHECKOUT_SET_BILLING_INFO,
  CHECKOUT_SET_BILLING_RECURLY_TOKEN,
  CHECKOUT_SET_CHECKOUT_ERROR,
  CHECKOUT_SET_CONTACT_INFO,
  CHECKOUT_SET_SIGNATURE_INFO,
  CHECKOUT_SET_COUPON,
  CHECKOUT_SET_CURRENT_SECTION,
  CHECKOUT_SET_PROVIDER_ACCOUNTS,
  CHECKOUT_SET_SUBSCRIPTIONS,
  CHECKOUT_SET_SUBSCRIPTION_BILLING_PERIOD,
  CHECKOUT_SET_SUBSCRIPTION_ITEMS,
  CHECKOUT_SET_SUBSCRIPTION_PLAN,
  CHECKOUT_SET_SUBSCRIPTION_TIER,
  CHECKOUT_SET_USERS,
  CHECKOUT_SET_USER_INFO,
  CHECKOUT_SUSPEND,
  CHECKOUT_TOGGLE_CONTACT_AS_BILLING_INFO,
  CHECKOUT_TOGGLE_TOS,
  CHECKOUT_UPDATE_PAYMENT_SUMMARY,
  CHECKOUT_VALIDATE_SECTION,
} from './types';

export const CHECKOUT_PLAN_SECTION_KEY = 'plan';
export const CHECKOUT_CONTACT_SECTION_KEY = 'contact';
export const CHECKOUT_SIGNATURE_SECTION_KEY = 'signature';
export const CHECKOUT_BILLING_SECTION_KEY = 'billing';
export const CHECKOUT_PRACTICE_USER_SECTION_KEY = 'users';
export const CHECKOUT_SUMMARY_SECTION_KEY = 'summary';

/**
 * The Checkout Reducer
 * Primarily handles checkout related events
 */
export default function CheckoutReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case CHECKOUT_INIT_CHECKOUT:
      return invokeAction(type, onCheckoutInitCheckout, state, payload);
    case CHECKOUT_SET_SUBSCRIPTIONS:
      return invokeAction(type, onCheckoutSetSubscriptions, state, payload);
    case CHECKOUT_SET_SUBSCRIPTION_ITEMS:
      return invokeAction(type, onCheckoutSetSubscriptionItems, state, payload);
    case CHECKOUT_PROGRESS_TO_NEXT_SECTION:
      return invokeAction(
        type,
        onCheckoutProgressToNextSection,
        state,
        payload
      );
    case CHECKOUT_SET_CURRENT_SECTION:
      return invokeAction(type, onCheckoutSetCurrentSection, state, payload);
    case CHECKOUT_SET_SUBSCRIPTION_PLAN:
      return invokeAction(type, onCheckoutSetSubscriptionPlan, state, payload);
    case CHECKOUT_REVISE_SECTION:
      return invokeAction(type, onCheckoutReviseSection, state, payload);
    case CHECKOUT_TOGGLE_CONTACT_AS_BILLING_INFO:
      return invokeAction(
        type,
        onCheckoutToggleContactAsBilling,
        state,
        payload
      );
    case CHECKOUT_SET_BILLING_INFO:
      return invokeAction(type, onCheckoutSetBillingInfo, state, payload);
    case CHECKOUT_SET_CONTACT_INFO:
      return invokeAction(type, onCheckoutSetContactInfo, state, payload);
    case CHECKOUT_SET_SIGNATURE_INFO:
      return invokeAction(type, onCheckoutSetSignatureInfo, state, payload);
    case CHECKOUT_SET_PROVIDER_ACCOUNTS:
      return invokeAction(type, onCheckoutSetProviderAccounts, state, payload);
    case CHECKOUT_SET_SUBSCRIPTION_BILLING_PERIOD:
      return invokeAction(
        type,
        onCheckoutSetSubscriptionBillingPeriod,
        state,
        payload
      );
    case CHECKOUT_UPDATE_PAYMENT_SUMMARY:
      return invokeAction(type, onCheckoutUpdatePaymentSummary, state, payload);
    case CHECKOUT_SET_USER_INFO:
      return invokeAction(type, onCheckoutSetUserInfo, state, payload);
    case CHECKOUT_CREATE_NEW_USER:
      return invokeAction(type, onCheckoutCreateNewUser, state, payload);
    case CHECKOUT_SET_SUBSCRIPTION_TIER:
      return invokeAction(type, onCheckoutSetSubscriptionTier, state, payload);
    case CHECKOUT_TOGGLE_TOS:
      return invokeAction(type, onCheckoutToggleTermsOfService, state, payload);
    case CHECKOUT_SET_BILLING_CARD_BRAND:
      return invokeAction(type, onCheckoutSetBillingCardBrand, state, payload);
    case CHECKOUT_SET_BILLING_CARD_LAST_FOUR:
      return invokeAction(
        type,
        onCheckoutSetBillingCardLastFour,
        state,
        payload
      );
    case CHECKOUT_SET_BILLING_CARD_VALIDATION:
      return invokeAction(
        type,
        onCheckoutSetBillingCardValidation,
        state,
        payload
      );
    case CHECKOUT_SET_BILLING_RECURLY_TOKEN:
      return invokeAction(
        type,
        onCheckoutSetBillingRecurlyToken,
        state,
        payload
      );
    case CHECKOUT_INIT_FLOW:
      return invokeAction(type, onCheckoutInitFlow, state, payload);
    case CHECKOUT_SET_USERS:
      return invokeAction(type, onCheckoutSetUsers, state, payload);
    case CHECKOUT_READY_PAGE:
      return invokeAction(type, onCheckoutPageReady, state, payload);
    case CHECKOUT_SET_CHECKOUT_ERROR:
      return invokeAction(type, onCheckoutSetCheckoutError, state, payload);
    case CHECKOUT_CHECKOUT_COMPLETED:
      return invokeAction(type, onCheckoutCompleted, state, payload);
    case CHECKOUT_VALIDATE_SECTION:
      return invokeAction(type, onCheckoutValidateSection, state, payload);
    case CHECKOUT_SUSPEND:
      return invokeAction(type, onCheckoutSuspension, state, payload);
    case CHECKOUT_SET_COUPON:
      return invokeAction(type, onCheckoutSetCoupon, state, payload);
    default:
      return state;
  }
}

const invokeAction = (type, action, state, payload) => action(state, payload);
