import React from 'react';
import { AmwellBlueHeart } from '../../icons';
import './SplashScreen.scss';

export default function SplashScreen({ label, icon }) {
  return (
    <div className="SplashScreen">
      <div className="SplashScreen-box">
        <div className="SplashScreen-box-icon">
          {icon || <AmwellBlueHeart />}
        </div>
        <span className="SplashScreen-box-label">
          {label || 'Please wait...'}
        </span>
      </div>
    </div>
  );
}
