import React from 'react';

export default function Hamburger() {
  return (
    <svg
      width="25"
      height="16"
      viewBox="0 0 25 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="25" height="2" fill="white" />
      <rect y="7" width="25" height="2" fill="white" />
      <rect y="14" width="25" height="2" fill="white" />
    </svg>
  );
}
