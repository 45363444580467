const getCouponDiscount = (coupon, total) => {
  let price = 0;
  let money = '';
  let name = '';

  if (coupon) {
    name = coupon.name;
    const { discount } = coupon;
    const { percent, type, currencies } = discount;
    switch (type) {
      case 'fixed': {
        const { amount } = currencies.find((curr) => curr.currency === 'USD');
        price = amount;
        money = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(price);
        break;
      }

      case 'percent':
        price = (percent / 100) * total.price;
        money = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(price);
        break;
      default:
        break;
    }
  }
  return { price, money, name };
};

export default getCouponDiscount;
