import React from 'react';

export default function AmwellWhiteLogo() {
  return (
    <svg
      width="130"
      height="31"
      viewBox="0 0 130 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.5642 3.77711C44.5937 4.29589 44.3757 4.79806 43.9761 5.13126C43.4945 5.4803 42.9055 5.64962 42.3116 5.60972H41.7191V7.73123H40.8145V2.05734H42.4518C43.0219 2.01769 43.588 2.17728 44.053 2.50872C44.4091 2.83109 44.5974 3.29851 44.5642 3.77711ZM41.7191 4.83335H42.2121C42.5808 4.86229 42.9492 4.77548 43.266 4.58508C43.4996 4.39761 43.6249 4.10685 43.6007 3.8087C43.6178 3.53141 43.5061 3.26169 43.2977 3.07746C43.0236 2.89343 42.6952 2.80751 42.3659 2.83372H41.7146L41.7191 4.83335Z"
        fill="white"
      />
      <path
        d="M49.4492 5.58708C49.485 6.18381 49.2908 6.77161 48.9064 7.23012C48.516 7.63882 47.965 7.85514 47.4002 7.82143C47.0264 7.82893 46.6576 7.73535 46.3328 7.5506C46.0218 7.36732 45.7729 7.09535 45.6181 6.7697C45.4455 6.39973 45.3603 5.9951 45.3693 5.58708C45.3342 4.99395 45.5266 4.40971 45.9076 3.95307C46.3026 3.54614 46.8561 3.33179 47.4228 3.36627C47.9817 3.33914 48.5243 3.55869 48.9064 3.96661C49.2865 4.41865 49.4805 4.99781 49.4492 5.58708ZM46.3147 5.58708C46.3147 6.58012 46.681 7.07665 47.4138 7.07665C48.142 7.07665 48.5084 6.58012 48.5084 5.58708C48.5084 4.59404 48.1194 4.12008 47.3867 4.12008C47.0595 4.09188 46.7415 4.23764 46.5499 4.50376C46.3568 4.83113 46.2641 5.20772 46.283 5.58708H46.3147Z"
        fill="white"
      />
      <path
        d="M54.0174 7.73114L53.4566 5.72248C53.3887 5.51033 53.2666 5.01832 53.0902 4.24194H53.0676C52.9138 4.94159 52.7962 5.43811 52.7057 5.73151L52.1268 7.73114H51.109L49.9014 3.44299H50.8377L51.3895 5.5645C51.5116 6.0881 51.6021 6.53497 51.6518 6.91865C51.679 6.72906 51.7197 6.5124 51.774 6.26414C51.8282 6.01588 51.8735 5.83533 51.9096 5.71345L52.5655 3.45653H53.5696L54.2074 5.71345C54.2481 5.84435 54.2934 6.03845 54.3522 6.30025C54.4016 6.5006 54.4363 6.70425 54.4562 6.90962H54.4878C54.5535 6.45364 54.6441 6.00158 54.7592 5.55547L55.3292 3.44299H56.2338L55.0442 7.73114H54.0174Z"
        fill="white"
      />
      <path
        d="M58.8844 7.82143C58.3014 7.85431 57.7311 7.64331 57.3104 7.23914C56.9166 6.79547 56.7135 6.21503 56.745 5.62319C56.7118 5.02564 56.8983 4.43655 57.2697 3.96661C57.6357 3.5575 58.1683 3.33661 58.7171 3.36627C59.2222 3.33728 59.7157 3.52444 60.074 3.88085C60.42 4.27307 60.5971 4.78555 60.5671 5.30722V5.79923H57.6949C57.6832 6.15081 57.8043 6.49393 58.0341 6.76068C58.2734 6.99713 58.6026 7.12031 58.9387 7.09921C59.1789 7.10162 59.4186 7.0774 59.6534 7.02699C59.8958 6.97035 60.1322 6.89022 60.359 6.78776V7.53254C60.143 7.63526 59.9151 7.71106 59.6805 7.75823C59.4176 7.80304 59.1512 7.82419 58.8844 7.82143ZM58.7171 4.05689C58.4544 4.04257 58.1985 4.14308 58.016 4.33223C57.8225 4.55805 57.7124 4.84321 57.7039 5.14021H59.6579C59.6746 4.84913 59.5845 4.56194 59.4046 4.33223C59.2253 4.14672 58.9751 4.04653 58.7171 4.05689Z"
        fill="white"
      />
      <path
        d="M63.7875 3.36628C63.939 3.36472 64.0904 3.37681 64.2398 3.4024L64.1493 4.25551C64.0159 4.22441 63.8793 4.20926 63.7423 4.21037C63.4033 4.19339 63.0735 4.3234 62.8376 4.56696C62.6057 4.81991 62.4837 5.15428 62.4984 5.49681V7.75373H61.5938V3.44302H62.3084L62.4305 4.20134H62.4758C62.6095 3.95516 62.8022 3.74581 63.0366 3.59198C63.2578 3.44157 63.5199 3.36277 63.7875 3.36628Z"
        fill="white"
      />
      <path
        d="M66.9259 7.82143C66.3429 7.85431 65.7725 7.64331 65.3519 7.23914C64.9551 6.79726 64.7515 6.21552 64.7865 5.62319C64.7511 5.02538 64.9379 4.43555 65.3112 3.96661C65.6772 3.5575 66.2098 3.33661 66.7586 3.36627C67.2636 3.33728 67.7572 3.52444 68.1155 3.88085C68.4615 4.27307 68.6386 4.78555 68.6085 5.30722V5.79923H65.7318C65.7202 6.15081 65.8412 6.49393 66.071 6.76068C66.3104 6.99713 66.6395 7.12031 66.9757 7.09921C67.2144 7.10193 67.4526 7.0777 67.6858 7.02699C67.9299 6.97109 68.1678 6.89094 68.3959 6.78776V7.53254C68.1794 7.63392 67.9517 7.70967 67.7175 7.75823C67.456 7.80258 67.1911 7.82373 66.9259 7.82143ZM66.7586 4.05689C66.4959 4.04257 66.2399 4.14308 66.0575 4.33223C65.864 4.55805 65.7538 4.84321 65.7454 5.14021H67.6994C67.7169 4.84829 67.625 4.56029 67.4416 4.33223C67.2665 4.14334 67.016 4.04236 66.7586 4.05689Z"
        fill="white"
      />
      <path
        d="M71.0968 7.8215C70.6063 7.84358 70.1356 7.62642 69.8348 7.23921C69.5072 6.75771 69.3482 6.18169 69.3825 5.60069C69.3444 5.01649 69.5037 4.43641 69.8348 3.95314C70.1356 3.5629 70.6084 3.34384 71.1013 3.36634C71.6112 3.33795 72.0999 3.57327 72.3949 3.98925H72.4447C72.4052 3.74736 72.3825 3.50303 72.3768 3.25801V1.69171H73.2814V7.73122H72.5668L72.4085 7.16699H72.3632C72.0837 7.58995 71.6042 7.83775 71.0968 7.8215ZM71.341 7.08574C71.6504 7.11232 71.9546 6.99389 72.1642 6.76526C72.3619 6.45984 72.4555 6.09889 72.4311 5.73611V5.60972C72.466 5.20612 72.3727 4.8018 72.1642 4.45418C71.9593 4.21146 71.6488 4.08348 71.332 4.11113C71.032 4.09978 70.749 4.24963 70.5902 4.50383C70.3944 4.8415 70.3031 5.22942 70.3278 5.61875C70.3047 5.9982 70.3927 6.37618 70.5811 6.70658C70.7485 6.95923 71.0381 7.10373 71.341 7.08574Z"
        fill="white"
      />
      <path
        d="M78.8893 3.36634C79.3787 3.34344 79.8483 3.56092 80.1467 3.94863C80.4765 4.42911 80.6358 5.00591 80.5991 5.58715C80.6347 6.17111 80.4756 6.75041 80.1467 7.2347C79.8305 7.60634 79.3666 7.82055 78.878 7.82055C78.3894 7.82055 77.9255 7.60634 77.6092 7.2347H77.5459L77.374 7.73122H76.6865V1.69171H77.5912V3.13162C77.5912 3.23996 77.5912 3.39794 77.5912 3.60558C77.5912 3.81773 77.5912 3.94863 77.564 4.00731H77.6047C77.889 3.58177 78.3776 3.33798 78.8893 3.36634ZM78.6541 4.12015C78.3508 4.08762 78.0515 4.20981 77.858 4.44515C77.665 4.77373 77.5766 5.15313 77.6047 5.53298V5.59618C77.573 5.99155 77.6596 6.38742 77.8535 6.73366C78.0479 6.98072 78.3541 7.11315 78.6677 7.08574C78.9656 7.09808 79.2478 6.95211 79.4095 6.70207C79.5959 6.36121 79.6821 5.97475 79.6582 5.58715C79.6582 4.59862 79.3235 4.12015 78.6541 4.12015Z"
        fill="white"
      />
      <path
        d="M81.0068 3.44299H82.0155L82.893 5.88046C83.0168 6.19616 83.1048 6.52471 83.1553 6.85997H83.187C83.2189 6.68418 83.2627 6.51075 83.3182 6.34088C83.3755 6.13625 83.7057 5.17029 84.3087 3.44299H85.2948L83.4539 8.31793C83.1192 9.2207 82.5598 9.67208 81.7758 9.67208C81.5763 9.67208 81.3775 9.64939 81.1832 9.60438V8.88216C81.3394 8.9148 81.4987 8.92993 81.6582 8.9273C82.1089 8.92053 82.4952 8.60423 82.5899 8.16446L82.7482 7.75822L81.0068 3.44299Z"
        fill="white"
      />
      <path
        d="M45.3195 13.4502C46.3169 13.4295 47.3102 13.5823 48.255 13.9016C48.9806 14.1454 49.6346 14.564 50.1593 15.1203C50.6453 15.6621 50.9943 16.3122 51.177 17.0161C51.3868 17.8161 51.4887 18.6403 51.4801 19.4672V28.6483C50.9373 28.7627 50.1186 28.9026 49.024 29.0681C47.7969 29.2412 46.5588 29.3241 45.3195 29.3163C44.4801 29.3223 43.6426 29.2345 42.8227 29.0545C42.1282 28.9069 41.4711 28.6197 40.8913 28.2105C40.3524 27.8164 39.9215 27.2933 39.6384 26.6893C39.3176 25.97 39.163 25.1878 39.1861 24.4008C39.1602 23.6351 39.3348 22.8758 39.6927 22.198C40.0271 21.6036 40.5023 21.0999 41.0768 20.731C41.7015 20.3384 42.3928 20.063 43.1167 19.9185C43.9051 19.7537 44.7086 19.672 45.514 19.6748C45.9222 19.6753 46.3299 19.6994 46.7353 19.747C47.1918 19.803 47.6448 19.8843 48.0922 19.9908V19.4265C48.0935 19.0353 48.0448 18.6455 47.9475 18.2665C47.8538 17.9082 47.6789 17.5762 47.4364 17.296C47.1757 17.0023 46.8463 16.7774 46.4775 16.6415C45.9966 16.4745 45.4892 16.3965 44.9803 16.4113C44.232 16.4069 43.4846 16.4643 42.7458 16.5828C42.1835 16.6711 41.6296 16.807 41.0904 16.9891L40.6381 14.1499C41.2741 13.951 41.9243 13.8001 42.583 13.6985C43.4859 13.5345 44.4018 13.4514 45.3195 13.4502ZM45.6135 26.5403C46.4405 26.5603 47.2675 26.5013 48.0832 26.3643V22.4689C47.8046 22.3964 47.5222 22.3391 47.2373 22.2973C46.8347 22.2368 46.4278 22.2082 46.0206 22.2116C45.6255 22.2113 45.2309 22.2399 44.8401 22.2973C44.4686 22.3473 44.1071 22.4539 43.7681 22.6133C43.4579 22.7612 43.1915 22.9867 42.9946 23.2678C42.793 23.5825 42.6921 23.9508 42.7051 24.324C42.7051 25.1591 42.9675 25.7324 43.4922 26.0528C44.1427 26.4014 44.876 26.5668 45.6135 26.5313V26.5403Z"
        fill="white"
      />
      <path
        d="M87.1314 19.4672C86.6821 21.1704 86.2026 22.8255 85.693 24.4324C85.1834 26.0393 84.6753 27.562 84.1687 29.0004H81.2829C80.8939 28.0976 80.4854 27.082 80.0572 25.9536C79.629 24.8251 79.2023 23.6079 78.7771 22.3019C78.3519 21.0064 77.9222 19.6432 77.4971 18.2123C77.0719 16.7815 76.6512 15.3235 76.2441 13.8339H79.9667C80.1446 14.6464 80.3481 15.5206 80.5773 16.4565C80.8065 17.3923 81.0507 18.3493 81.3101 19.3273C81.5634 20.3083 81.8257 21.2607 82.0971 22.1845C82.3685 23.1144 82.6444 23.963 82.9158 24.7348C83.2053 23.8321 83.4948 22.9293 83.7752 21.9814C84.0556 21.0335 84.3225 20.0856 84.5758 19.1422C84.8291 18.1988 85.0688 17.269 85.2905 16.3707C85.5121 15.4724 85.7111 14.6284 85.8875 13.8339H88.6014C88.7763 14.6284 88.9693 15.4739 89.1804 16.3707C89.393 17.2735 89.6327 18.1762 89.8634 19.1422C90.094 20.1082 90.3654 21.0335 90.6459 21.9814C90.9263 22.9293 91.2113 23.8501 91.5008 24.7348C91.7541 23.9645 92.0164 23.1144 92.2878 22.1845C92.5592 21.2547 92.8275 20.3022 93.0929 19.3273C93.3553 18.3523 93.604 17.3953 93.8392 16.4565C94.0744 15.5176 94.278 14.6434 94.4499 13.8339H98.0684C97.6613 15.3385 97.2452 16.798 96.82 18.2123C96.3903 19.6432 95.9651 21.0064 95.5354 22.3019C95.1057 23.5973 94.6851 24.8161 94.2554 25.9536C93.8257 27.0911 93.414 28.1112 93.007 29.0004H90.1574C89.6568 27.568 89.1397 26.0454 88.6059 24.4324C88.0722 22.8195 87.5807 21.1644 87.1314 19.4672Z"
        fill="white"
      />
      <path
        d="M98.417 21.4848C98.3925 20.2886 98.5951 19.0985 99.0141 17.9775C99.3656 17.0424 99.9043 16.1885 100.597 15.4678C101.237 14.8125 102.007 14.2988 102.859 13.9602C103.69 13.6253 104.577 13.4522 105.473 13.4501C107.566 13.4501 109.199 14.0986 110.372 15.3956C111.545 16.6926 112.131 18.626 112.131 21.1959C112.131 21.39 112.131 21.6066 112.131 21.8459C112.131 22.0851 112.109 22.2973 112.086 22.5004H102.049C102.08 23.5807 102.546 24.6028 103.343 25.3351C104.106 26.0031 105.215 26.3371 106.672 26.3371C107.458 26.3444 108.242 26.2657 109.01 26.1024C109.581 25.9904 110.139 25.8256 110.679 25.6104L111.132 28.4812C110.873 28.6039 110.605 28.7065 110.331 28.7881C109.949 28.9046 109.561 29.001 109.169 29.077C108.716 29.1628 108.264 29.235 107.762 29.2937C107.25 29.352 106.735 29.3806 106.219 29.3795C105.03 29.4041 103.847 29.2034 102.732 28.7881C101.809 28.4302 100.98 27.8667 100.308 27.1406C99.6584 26.4205 99.1724 25.5691 98.8829 24.6444C98.5631 23.622 98.4059 22.5558 98.417 21.4848ZM108.617 19.9185C108.617 19.4595 108.548 19.003 108.413 18.5643C108.287 18.1421 108.078 17.7492 107.798 17.4088C107.538 17.0916 107.213 16.8345 106.844 16.655C105.954 16.2769 104.947 16.2883 104.066 16.6866C103.673 16.88 103.322 17.1501 103.035 17.481C102.752 17.8175 102.53 18.201 102.379 18.614C102.227 19.0362 102.121 19.4735 102.063 19.9185H108.617Z"
        fill="white"
      />
      <path
        d="M119.69 29.2849C118.811 29.2896 117.936 29.1726 117.089 28.9372C116.478 28.7691 115.916 28.4599 115.447 28.0345C115.034 27.626 114.737 27.1149 114.588 26.5539C114.416 25.8953 114.334 25.2167 114.343 24.5362V7.07222L117.858 6.49445V23.8637C117.852 24.2231 117.887 24.5819 117.962 24.9335C118.019 25.2064 118.143 25.4609 118.324 25.6737C118.517 25.8837 118.762 26.0394 119.034 26.1251C119.407 26.2485 119.792 26.3317 120.183 26.3734L119.69 29.2849Z"
        fill="white"
      />
      <path
        d="M126.864 29.2848C125.985 29.2925 125.11 29.1786 124.263 28.9463C123.652 28.7769 123.09 28.4678 122.621 28.0435C122.209 27.6348 121.914 27.1236 121.766 26.563C121.593 25.9046 121.509 25.2259 121.517 24.5453V7.07222L125.036 6.49445V23.8637C125.029 24.2229 125.062 24.5817 125.136 24.9335C125.195 25.2058 125.319 25.4598 125.498 25.6737C125.691 25.8849 125.938 26.0407 126.212 26.1251C126.585 26.2491 126.97 26.3324 127.361 26.3734L126.864 29.2848Z"
        fill="white"
      />
      <path
        d="M74.6839 17.6616C74.5458 16.8707 74.2372 16.1191 73.7793 15.4589C73.3161 14.8304 72.6908 14.3391 71.9701 14.037C71.0432 13.6681 70.0499 13.4945 69.0526 13.527C68.5875 13.5276 68.1237 13.576 67.6685 13.6714C67.2316 13.7663 66.8011 13.8884 66.3794 14.037C66.0018 14.1639 65.6343 14.3194 65.2803 14.502C65.0132 14.6361 64.7549 14.7868 64.5068 14.9533C64.261 14.7858 64.0041 14.6349 63.7379 14.502C63.3824 14.3153 63.0135 14.1554 62.6342 14.0235C62.2113 13.8743 61.7792 13.7522 61.3406 13.6579C60.887 13.5623 60.4247 13.5139 59.9611 13.5134C58.9623 13.4809 57.9675 13.6546 57.0391 14.0235C56.318 14.3249 55.6925 14.8164 55.2298 15.4453C54.7701 16.1045 54.4612 16.8565 54.3252 17.6481C54.1542 18.5802 54.0709 19.5262 54.0764 20.4738V28.9959H57.5954V21.0199C57.5954 19.4371 57.7884 18.2921 58.1744 17.5849C58.5634 16.8807 59.3097 16.5242 60.4134 16.5242C60.9576 16.5226 61.4979 16.6158 62.0101 16.7995C62.2581 16.8904 62.5012 16.9943 62.7383 17.111V28.9959H66.2528V17.111C66.4894 16.9935 66.7326 16.8895 66.981 16.7995C67.4931 16.6158 68.0335 16.5226 68.5777 16.5242C69.6813 16.5242 70.4276 16.8807 70.8166 17.5849C71.2056 18.2891 71.3956 19.4356 71.3956 21.0199V28.9959H74.9101V20.4692C74.9218 19.5284 74.8461 18.5885 74.6839 17.6616Z"
        fill="white"
      />
      <path
        d="M16.5339 31C16.135 30.9958 15.7542 30.8334 15.4755 30.5486L11.2373 26.3778C10.8543 26.0005 10.7021 25.4479 10.8381 24.9282C10.9741 24.4085 11.3775 24.0007 11.8965 23.8584C12.4155 23.7161 12.9711 23.8609 13.3541 24.2383L16.5203 27.3664L27.8282 16.3391C30.7072 13.197 30.7072 8.38163 27.8282 5.23954C26.5196 3.80596 24.6675 2.98706 22.7243 2.98277C20.781 2.97848 18.9254 3.78919 17.6104 5.21698C17.3267 5.51711 16.9315 5.68723 16.518 5.68723C16.1046 5.68723 15.7094 5.51711 15.4257 5.21698C14.11 3.79045 12.2546 2.98065 10.3118 2.98494C8.36902 2.98923 6.51726 3.80722 5.20789 5.23954C3.24203 7.37794 2.55409 10.3935 3.39862 13.1704C3.55618 13.692 3.42288 14.258 3.04893 14.655C2.67498 15.052 2.11719 15.2198 1.58569 15.0951C1.05418 14.9704 0.629697 14.5722 0.47214 14.0505C-0.668208 10.249 0.28993 6.12908 2.99154 3.21735C6.52033 -0.618778 12.424 -1.07061 16.4977 2.18368C18.5415 0.539806 21.1567 -0.22511 23.7664 0.0576617C26.1549 0.33611 28.3653 1.45663 29.9993 3.21735C33.9578 7.53061 33.9578 14.1473 29.9993 18.4606L29.9586 18.5057L17.5878 30.5712C17.3059 30.8458 16.9278 30.9996 16.5339 31Z"
        fill="white"
      />
      <path
        d="M8.80811 23.3265C8.36632 23.3265 7.94228 23.1529 7.62757 22.8435L3.64718 18.9752C3.2133 18.551 3.0391 17.9281 3.19022 17.3411C3.34134 16.754 3.79481 16.292 4.37981 16.1291C4.96482 15.9662 5.59248 16.1271 6.02637 16.5512L8.81716 19.2595L18.4651 9.82561C18.89 9.36404 19.5358 9.1746 20.1434 9.33324C20.7511 9.49188 21.2211 9.97262 21.3651 10.5827C21.5091 11.1928 21.3035 11.8324 20.8307 12.245L9.9977 22.8435C9.67939 23.1531 9.25256 23.3265 8.80811 23.3265Z"
        fill="white"
      />
      <path
        d="M126.374 8.18264C126.375 7.53824 126.72 6.94305 127.279 6.62085C127.553 6.46032 127.865 6.37615 128.183 6.37709C128.831 6.37453 129.431 6.71765 129.756 7.27675C130.081 7.83586 130.081 8.52566 129.757 9.08541C129.605 9.3629 129.378 9.5926 129.102 9.74894C128.828 9.91325 128.516 10.0006 128.197 10.0017C127.879 9.99978 127.567 9.91415 127.292 9.75345C127.007 9.59855 126.77 9.36718 126.609 9.08541C126.452 8.81081 126.37 8.49911 126.374 8.18264ZM126.627 8.18264C126.624 8.74075 126.921 9.25774 127.405 9.53679C127.887 9.81323 128.48 9.81323 128.961 9.53679C129.191 9.39297 129.38 9.1924 129.509 8.9545C129.649 8.71831 129.723 8.44835 129.721 8.17361C129.721 7.9025 129.65 7.63618 129.513 7.40174C129.382 7.16636 129.192 6.96888 128.961 6.82849C128.726 6.6883 128.457 6.61494 128.183 6.61631C127.624 6.61296 127.106 6.90936 126.826 7.39272C126.688 7.63255 126.619 7.90602 126.627 8.18264ZM128.916 7.73125C128.917 7.8521 128.881 7.97033 128.812 8.06979C128.743 8.17048 128.643 8.24639 128.527 8.28646L129.097 9.23888H128.695L128.197 8.38576H127.871V9.23888H127.514V7.10835H128.152C128.36 7.0903 128.567 7.14452 128.74 7.26182C128.865 7.38444 128.93 7.55641 128.916 7.73125ZM127.871 8.09236H128.152C128.263 8.0982 128.373 8.06284 128.459 7.99306C128.534 7.9303 128.577 7.83775 128.577 7.74028C128.585 7.64156 128.547 7.54465 128.473 7.47848C128.375 7.4207 128.261 7.39394 128.147 7.40174H127.889L127.871 8.09236Z"
        fill="white"
      />
    </svg>
  );
}
