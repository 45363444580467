import {
  CHECKOUT_BILLING_SECTION_KEY,
  CHECKOUT_CONTACT_SECTION_KEY,
  CHECKOUT_SIGNATURE_SECTION_KEY,
  CHECKOUT_PLAN_SECTION_KEY,
  CHECKOUT_PRACTICE_USER_SECTION_KEY,
  CHECKOUT_SUMMARY_SECTION_KEY,
} from '..';
import {
  ValidateCheckoutBillingInformation,
  ValidateCheckoutContactInformation,
  ValidateCheckoutPlan,
  ValidatePracticeUsers,
} from '../../../validators';
import onCheckoutSetCurrentSection from './onCheckoutSetCurrentSection';

export default function onCheckoutProgressToNextSection(state, payload) {
  const checkout = { ...state.checkout };
  const { currentSection } = checkout;
  const { sectionKey } = payload;

  console.log('onCheckoutProgressToNextSection', {
    currentSection,
    sectionKey,
  });

  // TODO: If(currentSection !== sectionKey)

  /**
   * Checks Validation of currentSection (set status "incomplete" || "complete")
   */
  if (sectionKey) {
    let validation;
    let field;
    switch (sectionKey) {
      case CHECKOUT_PLAN_SECTION_KEY:
        field = checkout[CHECKOUT_PLAN_SECTION_KEY];
        validation = ValidateCheckoutPlan(state, field);
        break;
      case CHECKOUT_CONTACT_SECTION_KEY:
        field = checkout[CHECKOUT_CONTACT_SECTION_KEY];
        validation = ValidateCheckoutContactInformation(state, field);
        break;
      case CHECKOUT_SIGNATURE_SECTION_KEY:
        field = checkout[CHECKOUT_SIGNATURE_SECTION_KEY];
        validation = ValidateCheckoutContactInformation(state, field);
        break;
      case CHECKOUT_BILLING_SECTION_KEY:
        field = checkout[CHECKOUT_BILLING_SECTION_KEY];
        validation = ValidateCheckoutBillingInformation(state, field, {
          checkToken: true,
        });
        break;
      case CHECKOUT_PRACTICE_USER_SECTION_KEY:
        field = checkout[CHECKOUT_PRACTICE_USER_SECTION_KEY];
        validation = ValidatePracticeUsers(state, field);
        break;
      default:
        break;
    }

    checkout[sectionKey].validation = validation;
    checkout[sectionKey].status = validation.isValid
      ? 'completed'
      : 'incomplete';
  }

  const nextSection = getNextIncompleteSection(checkout);
  // eslint-disable-next-line no-return-assign
  return onCheckoutSetCurrentSection(
    state,
    // eslint-disable-next-line no-param-reassign
    (payload = {
      previousSection: sectionKey,
      currentSection: nextSection,
    })
  );
}

/**
 * Checks a objects fields "status" property
 */
function isFieldIncomplete(field) {
  return field.status === 'incomplete';
}

/**
 * Creates a list of the checkout sections and finds the first incomplete section
 * With return CHECKOUT_SUMMARY_SECTION_KEY if section is complete
 */
function getNextIncompleteSection(checkout) {
  const planSection = checkout[CHECKOUT_PLAN_SECTION_KEY];
  const { freePlan } = planSection;
  const contactSection = checkout[CHECKOUT_CONTACT_SECTION_KEY];
  const signatureSection = checkout[CHECKOUT_SIGNATURE_SECTION_KEY];
  const billingSection = checkout[CHECKOUT_BILLING_SECTION_KEY];
  const usersSection = checkout[CHECKOUT_PRACTICE_USER_SECTION_KEY];
  const sections = freePlan
    ? [planSection, contactSection, signatureSection, usersSection]
    : [planSection, contactSection, signatureSection, billingSection, usersSection];

  const incompleteSection = sections.find((section) =>
    isFieldIncomplete(section)
  );
  console.log('getNextIncompleteSection', { sections, incompleteSection });
  return incompleteSection
    ? incompleteSection.key
    : CHECKOUT_SUMMARY_SECTION_KEY;
}
