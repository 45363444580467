import { CHECKOUT_PLAN_SECTION_KEY } from "../events/checkout"

/**
 *
 contactInfo: {
      company: "",
      website: "",
      practiceSize: 0,
      firstName: "",
      lastName: "",
      email: "",
      confirmEmail: "",
      password: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "US"
    }
 *
 */
export default function ValidateCheckoutContactInformation(
  state,
  checkoutContact
) {
  const { checkout } = state
  const { freePlan } = checkout[CHECKOUT_PLAN_SECTION_KEY]
  const { contactInfo } = checkoutContact
  const validation = {
    isValid: true,
    error: "",
    fields: {},
  }

  // eslint-disable-next-line guard-for-in
  for (const key in contactInfo) {
    const value = contactInfo[key]
    let foundErrors = []
    switch (key) {
      case "company":
        foundErrors = isNotEmpty(value, foundErrors)
        break
      case "firstName":
        foundErrors = isNotEmpty(value, foundErrors)
        break
      case "lastName":
        foundErrors = isNotEmpty(value, foundErrors)
        break
      case "email":
        foundErrors = isNotEmpty(value, foundErrors)
        foundErrors = isEmail(value, foundErrors)
        break
      case "password":
        foundErrors = isNotEmpty(value, foundErrors)
        foundErrors = isValidPassword(value, foundErrors)
        break
      case "address1":
        foundErrors = freePlan
          ? foundErrors
          : isNotEmpty(value, foundErrors)
        break
      case "city":
        foundErrors = freePlan
          ? foundErrors
          : isNotEmpty(value, foundErrors)
        break
      case "state":
        foundErrors = isNotEmpty(value, foundErrors)
        break
      case "zip":
        foundErrors = freePlan
          ? foundErrors
          : isNotEmpty(value, foundErrors)
        foundErrors = freePlan
          ? foundErrors
          : isValidZipCode(value, foundErrors)
        break
      default:
    }

    if (foundErrors.length) {
      validation.isValid = false
      validation.fields[key] = foundErrors
    }
  }

  return validation
}

export const isNotEmpty = (value, foundErrors) => {
  if (!value || value.trim() === "" || value === '0') {
    foundErrors.push("This field is required")
  }
  return foundErrors
}

export const isEmail = (value, foundErrors) => {
  const emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i
  if (!emailCheck.test(value)) {
    foundErrors.push("A valid email must be provided")
  }
  return foundErrors
}

/**
 const isMatchingEmail = (value, foundErrors, contactInfo) => {
   if (contactInfo.email !== value) {
     foundErrors.push("Emails do not match")
    }
    return foundErrors
  }
 */

export const isValidPassword = (value, foundErrors) => {
  // eslint-disable-next-line
  const hasUppercase = /(?=.*[A-Z])/
  // eslint-disable-next-line
  const hasLowercase = /(?=.*[a-z])/
  // eslint-disable-next-line
  const hasNumber = /(?=.*[0-9])/
  // eslint-disable-next-line
  const hasLetter = /(?=.*[a-zA-Z])/
  // eslint-disable-next-line
  const hasSpecialCharacter = /(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])/
  // eslint-disable-next-line
  const hasNoSpaces = /^\S*$/

  // if (!hasUppercase.test(value)) {
  //   foundErrors.push("Password must have at least one uppercase character")
  // }
  // if (!hasLowercase.test(value)) {
  //   foundErrors.push("Password must have at least one lowercase character")
  // }
  if (!hasNumber.test(value)) {
    foundErrors.push("Password must have at least one numeric value")
  }
  if (!hasLetter.test(value)) {
    foundErrors.push("Password must have at least one letter")
  }
  // if (!hasSpecialCharacter.test(value)) {
  //   foundErrors.push("Password must have at least one special character")
  // }
  if (value && value.length < 10) {
    foundErrors.push("Password must be least 10 characters long")
  }
  if (!hasNoSpaces.test(value)) {
    foundErrors.push("Password must not contain spaces")
  }

  return foundErrors
}

const isValidZipCode = (value, foundErrors) => {
  const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  if (!isValidZip.test(value)) {
    foundErrors.push("Please provide a valid zip code")
  }
  return foundErrors
}
