import {
  onAppClearUserData,
  onAppInitCheckout,
  onAppSetUserData,
  onAppSetUserProviderListData,
  onAppSetUserSubscriptionData,
  onAppSetUserDocumentsData,
} from './actions';

import {
  APP_CLEAR_USER_DATA,
  APP_INIT_CHECKOUT,
  APP_SET_USER_DATA,
  APP_SET_USER_PROVIDER_LIST_DATA,
  APP_SET_USER_SUBSCRIPTION_DATA,
  APP_SET_USER_DOCUMENTS_DATA
} from './types';

/**
 * The App reducer
 * Primarily handles general app events
 */
export default function AppReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case APP_INIT_CHECKOUT:
      return invokeAction(type, onAppInitCheckout, state, payload);
    case APP_SET_USER_DATA:
      return invokeAction(type, onAppSetUserData, state, payload);
    case APP_CLEAR_USER_DATA:
      return invokeAction(type, onAppClearUserData, state, payload);
    case APP_SET_USER_SUBSCRIPTION_DATA:
      return invokeAction(type, onAppSetUserSubscriptionData, state, payload);
    case APP_SET_USER_PROVIDER_LIST_DATA:
      return invokeAction(type, onAppSetUserProviderListData, state, payload);
    case APP_SET_USER_DOCUMENTS_DATA:
      return invokeAction(type, onAppSetUserDocumentsData, state, payload);
    default:
      return state;
  }
}

const invokeAction = (type, action, state, payload) => action(state, payload);
