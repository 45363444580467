import React, { createContext, useEffect, useReducer } from 'react';
import EventReducer from '../events';
import {
  APP_SET_USER_DATA,
  APP_SET_USER_SUBSCRIPTION_DATA,
} from '../events/app/types';
import { SplashScreen } from '../../components/misc';
import { Amwell } from '../services';

// Keep an available array of supported locale languages
export const languages = ['es', 'en', 'de'];

// Expects an array of pathnames (eg: ["/", "/checkout"])
// Will return an array of pathnames including the locale languages ["/", "/es", "/checkout", "/es/checkout"]
const includeLocaleParam = (pathnameArr) =>
  pathnameArr.reduce(
    (acc, currentValue) => {
      const localePaths = languages.map((lang) => {
        if (currentValue === '/') {
          return `${currentValue}${lang}`;
        }
        return `/${lang}${currentValue}`;
      });
      return [...acc, ...localePaths];
    },
    [...pathnameArr]
  );

export const DispatchContext = createContext();
export const DispatchProvider = DispatchContext.Provider;
export const StateContext = createContext();
export const StateProvider = StateContext.Provider;

export const AppProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(EventReducer, {
    ready: true,
    authCheckDeffered: true,
    variables: {
      allowPlanSwitching: false,
      offerCommitmentOptions: false,
      allowPrefilledCheckoutPlan: false,
      offerFreePlan: false,
      defaultBillingPeriod: 'monthly',
    },
  });
  const { ready, authCheckDeffered } = state;

  /**
   * Attempts to fetch user information when the app mounts
   */
  useEffect(() => {
    const { pathname } = window.location;
    (async () => {
      /**
       * dispatch({ type: "suspend" }) is used if async data is criticaly needed to render the page.
       * This only happens on the first site load
       * Paths can be added below as a way to deffer awaiting async data with a splashscreen
       */
      const unprotectedPaths = ['/', '/checkout'];
      const unprotectedPathsWithLocales = includeLocaleParam(unprotectedPaths);
      if (!unprotectedPathsWithLocales.includes(pathname)) {
        dispatch({ type: 'merge', payload: { authCheckDeffered: false } });
        dispatch({ type: 'suspend' });
      }

      try {
        /**
         * Fetch and save user info to state
         */
        const { user, subscriptionData } = await Amwell.fetchCurrentUserInfo();
        if (user && subscriptionData) {
          dispatch({ type: APP_SET_USER_DATA, payload: { user } });
          dispatch({
            type: APP_SET_USER_SUBSCRIPTION_DATA,
            payload: { subscription: subscriptionData[0] },
          });
        }
        dispatch({ type: 'ready' });
      } catch (error) {
        console.error('Startup error', error);
        /**
         * TODO: Dispatch errors to state.
         * Always ready the applicate using dispatch({ type: "ready" })
         * Pages can watch and hanlde errors if crtical
         */
        dispatch({ type: 'ready' });
        dispatch({ type: APP_SET_USER_DATA, payload: { user: false } });
      }
    })();
  }, []);

  return (
    <DispatchProvider value={dispatch}>
      <StateProvider value={state}>
        {ready || authCheckDeffered ? <>{children}</> : <SplashScreen />}
      </StateProvider>
    </DispatchProvider>
  );
};
