export default function onAppSetUserProviderListData(state, payload) {
  let { providers } = payload;
  const user = { ...state.user };
  providers = providers.filter(
    // eslint-disable-next-line camelcase
    ({ AWPP_Relationship__c }) => AWPP_Relationship__c === 'Provider'
  );
  user.providerAccounts = providers;
  return { ...state, user };
}
