import React, { useEffect, useState } from "react"
import { LoadingSpinner } from ".."
import "./ConfirmBox.scss"

export default function ConfirmBox({
  onConfirm: handleConfirm,
  onCancel: handleCancel,
  heading = "",
  message = "",
  confirmLabel = "",
  cancelLabel = "",
  successHeading = "Success",
  successMessage = "",
  failedHeading = "Sorry, there seems to be an issue",
  failedMessage = "",
  processing,
  failed,
  success,
  providerAccounts,
  providerDeleteCount,
}) {
  const [status, setStatus] = useState(null)
  const [disabled, setDisabled] = useState(!!providerAccounts)
  const [providersIdsToDelete, setProvidersIdsToDelete] = useState([])

  useEffect(() => {
    console.log({ processing, failed, success })
    if (processing) setStatus("processing")
    if (failed) setStatus("failed")
    if (success) setStatus("success")
  }, [processing, failed, success])

  const confirm = () => {
    console.log("confirm")
    if (providerDeleteCount) {
      handleConfirm(providersIdsToDelete)
    } else {
      handleConfirm()
    }
  }
  const cancel = () => {
    console.log("cancel")
    handleCancel(success)
  }

  const handleCheckboxes = e => {
    e.preventDefault()
    if (!e.target.checked) {
      const temp = [...providersIdsToDelete]
      temp.splice(temp.indexOf(e.target.value), 1)
      setProvidersIdsToDelete(temp)
      if (!disabled) {
        setDisabled(true)
      }
    } else if (providersIdsToDelete.length < providerDeleteCount) {
        setProvidersIdsToDelete([...providersIdsToDelete, e.target.value])
        if (providersIdsToDelete.length + 1 === providerDeleteCount) {
          setDisabled(false)
        }
      }
  }

  const ViewSwitch = () => {
    console.log("ConfirmBox provider accounts", providerAccounts)
    switch (status) {
      case "processing":
        return <LoadingSpinner message={"Processing"} />
      case "success":
        return (
          <>
            <button className="ConfirmBox-box-close" onClick={cancel}>
              Close
            </button>
            <span className="ConfirmBox-box-heading">{successHeading}</span>
            <span className="ConfirmBox-box-copy">{successMessage}</span>
          </>
        )
      case "failed":
        return (
          <>
            <button className="ConfirmBox-box-close" onClick={cancel}>
              Close
            </button>
            <span className="ConfirmBox-box-heading">{failedHeading}</span>
            <span className="ConfirmBox-box-copy">{failedMessage}</span>
            <span className="ConfirmBox-box-copy">
              For more information or to get help please email{" "}
              <a href="emailto:private.practice@amwell.com">
                private.practice@amwell.com
              </a>
            </span>
          </>
        )
      default:
        return (
          <>
            <button className="ConfirmBox-box-close" onClick={cancel}>
              Close
            </button>
            <span className="ConfirmBox-box-heading">{heading}</span>
            <span className="ConfirmBox-box-copy">{message}</span>

            {providerAccounts && (
              <div className="ConfirmBox-box-providers">
                {providerAccounts.map((account, i) => (
                  <div className="ConfirmBox-box-provider" key={i}>
                    <input
                      id={`provider_${  i}`}
                      type="checkbox"
                      name="providers"
                      value={account.Id}
                      checked={providersIdsToDelete.includes(account.Id)}
                      onChange={handleCheckboxes}
                    />
                    <label htmlFor={`provider_${  i}`}>
                      {account.Full_Name__c}
                    </label>
                  </div>
                ))}
              </div>
            )}

            <div className="ConfirmBox-box-methods">
              <button
                className="ConfirmBox-box-button confirm"
                disabled={disabled}
                onClick={confirm}
              >
                {confirmLabel}
              </button>
              <button className="ConfirmBox-box-button cancel" onClick={cancel}>
                {cancelLabel}
              </button>
            </div>
          </>
        )
    }
  }

  return (
    <div className="ConfirmBox">
      <div className="ConfirmBox-box">
        <ViewSwitch />
      </div>
    </div>
  )
}
