import React from 'react';

export default function PlusIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.60192 0.6C6.60192 0.268629 6.33329 0 6.00192 0C5.67055 0 5.40192 0.268629 5.40192 0.6V5.39915H0.6C0.268629 5.39915 0 5.66778 0 5.99915C0 6.33052 0.268629 6.59915 0.6 6.59915H5.40192V11.4C5.40192 11.7314 5.67055 12 6.00192 12C6.33329 12 6.60192 11.7314 6.60192 11.4V6.59915H11.4C11.7314 6.59915 12 6.33052 12 5.99915C12 5.66778 11.7314 5.39915 11.4 5.39915H6.60192V0.6Z"
        fill="#4F4F4F"
      />
    </svg>
  );
}
